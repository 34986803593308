import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { ProductComponent } from './product/product.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ShopFrontComponent } from './shop-front/shop-front.component';
import { ContactComponent } from './contact/contact.component';
import { OrderComponent } from './order/order.component';
import { AdminComponent } from './admin/admin.component';
import { AdminProductsComponent } from './admin-products/admin-products.component';
import { AdminProductTableComponent } from './admin-product-table/admin-product-table.component';
import { AdminCreateProductComponent } from './admin-create-product/admin-create-product.component';
import { AdminUpdateProductComponent } from './admin-update-product/admin-update-product.component';
import { AdminProductVariantTableComponent } from './admin-product-variant-table/admin-product-variant-table.component';
import { AdminCreateProductVariantComponent } from './admin-create-product-variant/admin-create-product-variant.component';
import { AdminUpdateProductVariantComponent } from './admin-update-product-variant/admin-update-product-variant.component';
import { AdminCategoriesComponent } from './admin-categories/admin-categories.component';
import { AdminCategoryTableComponent } from './admin-category-table/admin-category-table.component';
import { AdminCreateCategoryComponent } from './admin-create-category/admin-create-category.component';
import { AdminUpdateCategoryComponent } from './admin-update-category/admin-update-category.component';
import { AdminOrdersComponent } from './admin-orders/admin-orders.component';

const routes: Routes = [
  {
    path: 'shop',
    component: ShopComponent,
    children: [
      { path: 'product/:productID', component: ProductComponent },
      { path: 'order/:orderReference', component: OrderComponent },
      { path: 'checkout', component: CheckoutComponent },
      { path: '', pathMatch: 'full', component: ShopFrontComponent }
    ]
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: 'products',
        component: AdminProductsComponent,
        children: [
          { path: 'create', component: AdminCreateProductComponent },
          { path: 'update/:productID', component: AdminUpdateProductComponent },
          { path: 'variants/:productID/create', component: AdminCreateProductVariantComponent },
          { path: 'variants/:productID/update/:productVariantID', component: AdminUpdateProductVariantComponent },
          { path: 'variants/:productID', component: AdminProductVariantTableComponent },
          { path: '', pathMatch: 'full', component: AdminProductTableComponent }
        ]
      },
      {
        path: 'categories',
        component: AdminCategoriesComponent,
        children: [
          { path: 'create', component: AdminCreateCategoryComponent },
          { path: 'update/:categoryID', component: AdminUpdateCategoryComponent },
          { path: '', pathMatch: 'full', component: AdminCategoryTableComponent }
        ]
      },
      {
        path: 'orders',
        component: AdminOrdersComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'products'
      }
    ]
  },
  { path: 'contact', component: ContactComponent },
  { path: '', pathMatch: 'full', redirectTo: '/shop' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
