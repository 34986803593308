/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type CreateCategoryInput = {
  id?: string | null;
  name: string;
  slug: string;
};

export type ModelCategoryConditionInput = {
  name?: ModelStringInput | null;
  slug?: ModelStringInput | null;
  and?: Array<ModelCategoryConditionInput | null> | null;
  or?: Array<ModelCategoryConditionInput | null> | null;
  not?: ModelCategoryConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type UpdateCategoryInput = {
  id: string;
  name?: string | null;
  slug?: string | null;
};

export type DeleteCategoryInput = {
  id?: string | null;
};

export type CreateProductInput = {
  id?: string | null;
  categoryID: string;
  name: string;
  slug: string;
  code: string;
  description: string;
  allergens?: string | null;
  abv?: string | null;
  sortPriority: number;
  inStock: boolean;
  active: boolean;
};

export type ModelProductConditionInput = {
  categoryID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  slug?: ModelStringInput | null;
  code?: ModelStringInput | null;
  description?: ModelStringInput | null;
  allergens?: ModelStringInput | null;
  abv?: ModelStringInput | null;
  sortPriority?: ModelIntInput | null;
  inStock?: ModelBooleanInput | null;
  active?: ModelBooleanInput | null;
  and?: Array<ModelProductConditionInput | null> | null;
  or?: Array<ModelProductConditionInput | null> | null;
  not?: ModelProductConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateProductInput = {
  id: string;
  categoryID?: string | null;
  name?: string | null;
  slug?: string | null;
  code?: string | null;
  description?: string | null;
  allergens?: string | null;
  abv?: string | null;
  sortPriority?: number | null;
  inStock?: boolean | null;
  active?: boolean | null;
};

export type DeleteProductInput = {
  id?: string | null;
};

export type CreateProductVariantInput = {
  id?: string | null;
  productID: string;
  name: string;
  code: string;
  description: string;
  price: number;
  inStock: boolean;
  active: boolean;
};

export type ModelProductVariantConditionInput = {
  productID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  code?: ModelStringInput | null;
  description?: ModelStringInput | null;
  price?: ModelFloatInput | null;
  inStock?: ModelBooleanInput | null;
  active?: ModelBooleanInput | null;
  and?: Array<ModelProductVariantConditionInput | null> | null;
  or?: Array<ModelProductVariantConditionInput | null> | null;
  not?: ModelProductVariantConditionInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateProductVariantInput = {
  id: string;
  productID?: string | null;
  name?: string | null;
  code?: string | null;
  description?: string | null;
  price?: number | null;
  inStock?: boolean | null;
  active?: boolean | null;
};

export type DeleteProductVariantInput = {
  id?: string | null;
};

export type CreateOrderInput = {
  id?: string | null;
  reference: string;
  updatedAt?: string | null;
  createdAt?: string | null;
  checkoutID: string;
  total: number;
  name: string;
  status: string;
  emailAddress: string;
  contactNumber?: string | null;
  notes?: string | null;
  deliveryAddressLine1?: string | null;
  deliveryAddressLine2?: string | null;
  deliveryTown?: string | null;
  deliveryPostalCode?: string | null;
  deliveryCountryCode?: string | null;
  marketingAllowed: boolean;
};

export type ModelOrderConditionInput = {
  reference?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  checkoutID?: ModelStringInput | null;
  total?: ModelFloatInput | null;
  name?: ModelStringInput | null;
  status?: ModelStringInput | null;
  emailAddress?: ModelStringInput | null;
  contactNumber?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  deliveryAddressLine1?: ModelStringInput | null;
  deliveryAddressLine2?: ModelStringInput | null;
  deliveryTown?: ModelStringInput | null;
  deliveryPostalCode?: ModelStringInput | null;
  deliveryCountryCode?: ModelStringInput | null;
  marketingAllowed?: ModelBooleanInput | null;
  and?: Array<ModelOrderConditionInput | null> | null;
  or?: Array<ModelOrderConditionInput | null> | null;
  not?: ModelOrderConditionInput | null;
};

export type UpdateOrderInput = {
  id: string;
  reference?: string | null;
  updatedAt?: string | null;
  createdAt?: string | null;
  checkoutID?: string | null;
  total?: number | null;
  name?: string | null;
  status?: string | null;
  emailAddress?: string | null;
  contactNumber?: string | null;
  notes?: string | null;
  deliveryAddressLine1?: string | null;
  deliveryAddressLine2?: string | null;
  deliveryTown?: string | null;
  deliveryPostalCode?: string | null;
  deliveryCountryCode?: string | null;
  marketingAllowed?: boolean | null;
};

export type DeleteOrderInput = {
  id?: string | null;
};

export type CreateOrderItemInput = {
  id?: string | null;
  orderID: string;
  productVariantID: string;
  productName: string;
  productCode: string;
  productVariantName: string;
  productVariantCode: string;
  productVariantPrice: number;
  quantity: number;
};

export type ModelOrderItemConditionInput = {
  orderID?: ModelIDInput | null;
  productVariantID?: ModelIDInput | null;
  productName?: ModelStringInput | null;
  productCode?: ModelStringInput | null;
  productVariantName?: ModelStringInput | null;
  productVariantCode?: ModelStringInput | null;
  productVariantPrice?: ModelFloatInput | null;
  quantity?: ModelIntInput | null;
  and?: Array<ModelOrderItemConditionInput | null> | null;
  or?: Array<ModelOrderItemConditionInput | null> | null;
  not?: ModelOrderItemConditionInput | null;
};

export type UpdateOrderItemInput = {
  id: string;
  orderID?: string | null;
  productVariantID?: string | null;
  productName?: string | null;
  productCode?: string | null;
  productVariantName?: string | null;
  productVariantCode?: string | null;
  productVariantPrice?: number | null;
  quantity?: number | null;
};

export type DeleteOrderItemInput = {
  id?: string | null;
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  slug?: ModelStringInput | null;
  and?: Array<ModelCategoryFilterInput | null> | null;
  or?: Array<ModelCategoryFilterInput | null> | null;
  not?: ModelCategoryFilterInput | null;
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null;
  categoryID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  slug?: ModelStringInput | null;
  code?: ModelStringInput | null;
  description?: ModelStringInput | null;
  allergens?: ModelStringInput | null;
  abv?: ModelStringInput | null;
  sortPriority?: ModelIntInput | null;
  inStock?: ModelBooleanInput | null;
  active?: ModelBooleanInput | null;
  and?: Array<ModelProductFilterInput | null> | null;
  or?: Array<ModelProductFilterInput | null> | null;
  not?: ModelProductFilterInput | null;
};

export type ModelProductVariantFilterInput = {
  id?: ModelIDInput | null;
  productID?: ModelIDInput | null;
  name?: ModelStringInput | null;
  code?: ModelStringInput | null;
  description?: ModelStringInput | null;
  price?: ModelFloatInput | null;
  inStock?: ModelBooleanInput | null;
  active?: ModelBooleanInput | null;
  and?: Array<ModelProductVariantFilterInput | null> | null;
  or?: Array<ModelProductVariantFilterInput | null> | null;
  not?: ModelProductVariantFilterInput | null;
};

export type ModelOrderFilterInput = {
  id?: ModelIDInput | null;
  reference?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  checkoutID?: ModelStringInput | null;
  total?: ModelFloatInput | null;
  name?: ModelStringInput | null;
  status?: ModelStringInput | null;
  emailAddress?: ModelStringInput | null;
  contactNumber?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  deliveryAddressLine1?: ModelStringInput | null;
  deliveryAddressLine2?: ModelStringInput | null;
  deliveryTown?: ModelStringInput | null;
  deliveryPostalCode?: ModelStringInput | null;
  deliveryCountryCode?: ModelStringInput | null;
  marketingAllowed?: ModelBooleanInput | null;
  and?: Array<ModelOrderFilterInput | null> | null;
  or?: Array<ModelOrderFilterInput | null> | null;
  not?: ModelOrderFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelOrderItemFilterInput = {
  id?: ModelIDInput | null;
  orderID?: ModelIDInput | null;
  productVariantID?: ModelIDInput | null;
  productName?: ModelStringInput | null;
  productCode?: ModelStringInput | null;
  productVariantName?: ModelStringInput | null;
  productVariantCode?: ModelStringInput | null;
  productVariantPrice?: ModelFloatInput | null;
  quantity?: ModelIntInput | null;
  and?: Array<ModelOrderItemFilterInput | null> | null;
  or?: Array<ModelOrderItemFilterInput | null> | null;
  not?: ModelOrderItemFilterInput | null;
};

export type CreateCategoryMutation = {
  __typename: "Category";
  id: string;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  products: {
    __typename: "ModelProductConnection";
    nextToken: string | null;
  } | null;
};

export type UpdateCategoryMutation = {
  __typename: "Category";
  id: string;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  products: {
    __typename: "ModelProductConnection";
    nextToken: string | null;
  } | null;
};

export type DeleteCategoryMutation = {
  __typename: "Category";
  id: string;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  products: {
    __typename: "ModelProductConnection";
    nextToken: string | null;
  } | null;
};

export type CreateProductMutation = {
  __typename: "Product";
  id: string;
  categoryID: string;
  name: string;
  slug: string;
  code: string;
  description: string;
  allergens: string | null;
  abv: string | null;
  sortPriority: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  category: {
    __typename: "Category";
    id: string;
    name: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  productVariants: {
    __typename: "ModelProductVariantConnection";
    nextToken: string | null;
  } | null;
};

export type UpdateProductMutation = {
  __typename: "Product";
  id: string;
  categoryID: string;
  name: string;
  slug: string;
  code: string;
  description: string;
  allergens: string | null;
  abv: string | null;
  sortPriority: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  category: {
    __typename: "Category";
    id: string;
    name: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  productVariants: {
    __typename: "ModelProductVariantConnection";
    nextToken: string | null;
  } | null;
};

export type DeleteProductMutation = {
  __typename: "Product";
  id: string;
  categoryID: string;
  name: string;
  slug: string;
  code: string;
  description: string;
  allergens: string | null;
  abv: string | null;
  sortPriority: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  category: {
    __typename: "Category";
    id: string;
    name: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  productVariants: {
    __typename: "ModelProductVariantConnection";
    nextToken: string | null;
  } | null;
};

export type CreateProductVariantMutation = {
  __typename: "ProductVariant";
  id: string;
  productID: string;
  name: string;
  code: string;
  description: string;
  price: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    categoryID: string;
    name: string;
    slug: string;
    code: string;
    description: string;
    allergens: string | null;
    abv: string | null;
    sortPriority: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateProductVariantMutation = {
  __typename: "ProductVariant";
  id: string;
  productID: string;
  name: string;
  code: string;
  description: string;
  price: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    categoryID: string;
    name: string;
    slug: string;
    code: string;
    description: string;
    allergens: string | null;
    abv: string | null;
    sortPriority: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteProductVariantMutation = {
  __typename: "ProductVariant";
  id: string;
  productID: string;
  name: string;
  code: string;
  description: string;
  price: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    categoryID: string;
    name: string;
    slug: string;
    code: string;
    description: string;
    allergens: string | null;
    abv: string | null;
    sortPriority: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateOrderMutation = {
  __typename: "Order";
  id: string;
  reference: string;
  updatedAt: string;
  createdAt: string;
  checkoutID: string;
  total: number;
  name: string;
  status: string;
  emailAddress: string;
  contactNumber: string | null;
  notes: string | null;
  deliveryAddressLine1: string | null;
  deliveryAddressLine2: string | null;
  deliveryTown: string | null;
  deliveryPostalCode: string | null;
  deliveryCountryCode: string | null;
  marketingAllowed: boolean;
  orderItems: {
    __typename: "ModelOrderItemConnection";
    nextToken: string | null;
  } | null;
};

export type UpdateOrderMutation = {
  __typename: "Order";
  id: string;
  reference: string;
  updatedAt: string;
  createdAt: string;
  checkoutID: string;
  total: number;
  name: string;
  status: string;
  emailAddress: string;
  contactNumber: string | null;
  notes: string | null;
  deliveryAddressLine1: string | null;
  deliveryAddressLine2: string | null;
  deliveryTown: string | null;
  deliveryPostalCode: string | null;
  deliveryCountryCode: string | null;
  marketingAllowed: boolean;
  orderItems: {
    __typename: "ModelOrderItemConnection";
    nextToken: string | null;
  } | null;
};

export type DeleteOrderMutation = {
  __typename: "Order";
  id: string;
  reference: string;
  updatedAt: string;
  createdAt: string;
  checkoutID: string;
  total: number;
  name: string;
  status: string;
  emailAddress: string;
  contactNumber: string | null;
  notes: string | null;
  deliveryAddressLine1: string | null;
  deliveryAddressLine2: string | null;
  deliveryTown: string | null;
  deliveryPostalCode: string | null;
  deliveryCountryCode: string | null;
  marketingAllowed: boolean;
  orderItems: {
    __typename: "ModelOrderItemConnection";
    nextToken: string | null;
  } | null;
};

export type CreateOrderItemMutation = {
  __typename: "OrderItem";
  id: string;
  orderID: string;
  productVariantID: string;
  productName: string;
  productCode: string;
  productVariantName: string;
  productVariantCode: string;
  productVariantPrice: number;
  quantity: number;
  createdAt: string;
  updatedAt: string;
  productVariant: {
    __typename: "ProductVariant";
    id: string;
    productID: string;
    name: string;
    code: string;
    description: string;
    price: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  order: {
    __typename: "Order";
    id: string;
    reference: string;
    updatedAt: string;
    createdAt: string;
    checkoutID: string;
    total: number;
    name: string;
    status: string;
    emailAddress: string;
    contactNumber: string | null;
    notes: string | null;
    deliveryAddressLine1: string | null;
    deliveryAddressLine2: string | null;
    deliveryTown: string | null;
    deliveryPostalCode: string | null;
    deliveryCountryCode: string | null;
    marketingAllowed: boolean;
  } | null;
};

export type UpdateOrderItemMutation = {
  __typename: "OrderItem";
  id: string;
  orderID: string;
  productVariantID: string;
  productName: string;
  productCode: string;
  productVariantName: string;
  productVariantCode: string;
  productVariantPrice: number;
  quantity: number;
  createdAt: string;
  updatedAt: string;
  productVariant: {
    __typename: "ProductVariant";
    id: string;
    productID: string;
    name: string;
    code: string;
    description: string;
    price: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  order: {
    __typename: "Order";
    id: string;
    reference: string;
    updatedAt: string;
    createdAt: string;
    checkoutID: string;
    total: number;
    name: string;
    status: string;
    emailAddress: string;
    contactNumber: string | null;
    notes: string | null;
    deliveryAddressLine1: string | null;
    deliveryAddressLine2: string | null;
    deliveryTown: string | null;
    deliveryPostalCode: string | null;
    deliveryCountryCode: string | null;
    marketingAllowed: boolean;
  } | null;
};

export type DeleteOrderItemMutation = {
  __typename: "OrderItem";
  id: string;
  orderID: string;
  productVariantID: string;
  productName: string;
  productCode: string;
  productVariantName: string;
  productVariantCode: string;
  productVariantPrice: number;
  quantity: number;
  createdAt: string;
  updatedAt: string;
  productVariant: {
    __typename: "ProductVariant";
    id: string;
    productID: string;
    name: string;
    code: string;
    description: string;
    price: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  order: {
    __typename: "Order";
    id: string;
    reference: string;
    updatedAt: string;
    createdAt: string;
    checkoutID: string;
    total: number;
    name: string;
    status: string;
    emailAddress: string;
    contactNumber: string | null;
    notes: string | null;
    deliveryAddressLine1: string | null;
    deliveryAddressLine2: string | null;
    deliveryTown: string | null;
    deliveryPostalCode: string | null;
    deliveryCountryCode: string | null;
    marketingAllowed: boolean;
  } | null;
};

export type ListCategorysQuery = {
  __typename: "ModelCategoryConnection";
  items: Array<{
    __typename: "Category";
    id: string;
    name: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetCategoryQuery = {
  __typename: "Category";
  id: string;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  products: {
    __typename: "ModelProductConnection";
    nextToken: string | null;
  } | null;
};

export type ListProductsQuery = {
  __typename: "ModelProductConnection";
  items: Array<{
    __typename: "Product";
    id: string;
    categoryID: string;
    name: string;
    slug: string;
    code: string;
    description: string;
    allergens: string | null;
    abv: string | null;
    sortPriority: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetProductQuery = {
  __typename: "Product";
  id: string;
  categoryID: string;
  name: string;
  slug: string;
  code: string;
  description: string;
  allergens: string | null;
  abv: string | null;
  sortPriority: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  category: {
    __typename: "Category";
    id: string;
    name: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  productVariants: {
    __typename: "ModelProductVariantConnection";
    nextToken: string | null;
  } | null;
};

export type ListProductVariantsQuery = {
  __typename: "ModelProductVariantConnection";
  items: Array<{
    __typename: "ProductVariant";
    id: string;
    productID: string;
    name: string;
    code: string;
    description: string;
    price: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetProductVariantQuery = {
  __typename: "ProductVariant";
  id: string;
  productID: string;
  name: string;
  code: string;
  description: string;
  price: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    categoryID: string;
    name: string;
    slug: string;
    code: string;
    description: string;
    allergens: string | null;
    abv: string | null;
    sortPriority: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListOrdersQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    id: string;
    reference: string;
    updatedAt: string;
    createdAt: string;
    checkoutID: string;
    total: number;
    name: string;
    status: string;
    emailAddress: string;
    contactNumber: string | null;
    notes: string | null;
    deliveryAddressLine1: string | null;
    deliveryAddressLine2: string | null;
    deliveryTown: string | null;
    deliveryPostalCode: string | null;
    deliveryCountryCode: string | null;
    marketingAllowed: boolean;
  } | null> | null;
  nextToken: string | null;
};

export type GetOrderQuery = {
  __typename: "Order";
  id: string;
  reference: string;
  updatedAt: string;
  createdAt: string;
  checkoutID: string;
  total: number;
  name: string;
  status: string;
  emailAddress: string;
  contactNumber: string | null;
  notes: string | null;
  deliveryAddressLine1: string | null;
  deliveryAddressLine2: string | null;
  deliveryTown: string | null;
  deliveryPostalCode: string | null;
  deliveryCountryCode: string | null;
  marketingAllowed: boolean;
  orderItems: {
    __typename: "ModelOrderItemConnection";
    nextToken: string | null;
  } | null;
};

export type OrdersByReferenceQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    id: string;
    reference: string;
    updatedAt: string;
    createdAt: string;
    checkoutID: string;
    total: number;
    name: string;
    status: string;
    emailAddress: string;
    contactNumber: string | null;
    notes: string | null;
    deliveryAddressLine1: string | null;
    deliveryAddressLine2: string | null;
    deliveryTown: string | null;
    deliveryPostalCode: string | null;
    deliveryCountryCode: string | null;
    marketingAllowed: boolean;
  } | null> | null;
  nextToken: string | null;
};

export type OrdersByStatusQuery = {
  __typename: "ModelOrderConnection";
  items: Array<{
    __typename: "Order";
    id: string;
    reference: string;
    updatedAt: string;
    createdAt: string;
    checkoutID: string;
    total: number;
    name: string;
    status: string;
    emailAddress: string;
    contactNumber: string | null;
    notes: string | null;
    deliveryAddressLine1: string | null;
    deliveryAddressLine2: string | null;
    deliveryTown: string | null;
    deliveryPostalCode: string | null;
    deliveryCountryCode: string | null;
    marketingAllowed: boolean;
  } | null> | null;
  nextToken: string | null;
};

export type GetOrderItemQuery = {
  __typename: "OrderItem";
  id: string;
  orderID: string;
  productVariantID: string;
  productName: string;
  productCode: string;
  productVariantName: string;
  productVariantCode: string;
  productVariantPrice: number;
  quantity: number;
  createdAt: string;
  updatedAt: string;
  productVariant: {
    __typename: "ProductVariant";
    id: string;
    productID: string;
    name: string;
    code: string;
    description: string;
    price: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  order: {
    __typename: "Order";
    id: string;
    reference: string;
    updatedAt: string;
    createdAt: string;
    checkoutID: string;
    total: number;
    name: string;
    status: string;
    emailAddress: string;
    contactNumber: string | null;
    notes: string | null;
    deliveryAddressLine1: string | null;
    deliveryAddressLine2: string | null;
    deliveryTown: string | null;
    deliveryPostalCode: string | null;
    deliveryCountryCode: string | null;
    marketingAllowed: boolean;
  } | null;
};

export type ListOrderItemsQuery = {
  __typename: "ModelOrderItemConnection";
  items: Array<{
    __typename: "OrderItem";
    id: string;
    orderID: string;
    productVariantID: string;
    productName: string;
    productCode: string;
    productVariantName: string;
    productVariantCode: string;
    productVariantPrice: number;
    quantity: number;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreateCategorySubscription = {
  __typename: "Category";
  id: string;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  products: {
    __typename: "ModelProductConnection";
    nextToken: string | null;
  } | null;
};

export type OnUpdateCategorySubscription = {
  __typename: "Category";
  id: string;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  products: {
    __typename: "ModelProductConnection";
    nextToken: string | null;
  } | null;
};

export type OnDeleteCategorySubscription = {
  __typename: "Category";
  id: string;
  name: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  products: {
    __typename: "ModelProductConnection";
    nextToken: string | null;
  } | null;
};

export type OnCreateProductSubscription = {
  __typename: "Product";
  id: string;
  categoryID: string;
  name: string;
  slug: string;
  code: string;
  description: string;
  allergens: string | null;
  abv: string | null;
  sortPriority: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  category: {
    __typename: "Category";
    id: string;
    name: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  productVariants: {
    __typename: "ModelProductVariantConnection";
    nextToken: string | null;
  } | null;
};

export type OnUpdateProductSubscription = {
  __typename: "Product";
  id: string;
  categoryID: string;
  name: string;
  slug: string;
  code: string;
  description: string;
  allergens: string | null;
  abv: string | null;
  sortPriority: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  category: {
    __typename: "Category";
    id: string;
    name: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  productVariants: {
    __typename: "ModelProductVariantConnection";
    nextToken: string | null;
  } | null;
};

export type OnDeleteProductSubscription = {
  __typename: "Product";
  id: string;
  categoryID: string;
  name: string;
  slug: string;
  code: string;
  description: string;
  allergens: string | null;
  abv: string | null;
  sortPriority: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  category: {
    __typename: "Category";
    id: string;
    name: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  productVariants: {
    __typename: "ModelProductVariantConnection";
    nextToken: string | null;
  } | null;
};

export type OnCreateProductVariantSubscription = {
  __typename: "ProductVariant";
  id: string;
  productID: string;
  name: string;
  code: string;
  description: string;
  price: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    categoryID: string;
    name: string;
    slug: string;
    code: string;
    description: string;
    allergens: string | null;
    abv: string | null;
    sortPriority: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateProductVariantSubscription = {
  __typename: "ProductVariant";
  id: string;
  productID: string;
  name: string;
  code: string;
  description: string;
  price: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    categoryID: string;
    name: string;
    slug: string;
    code: string;
    description: string;
    allergens: string | null;
    abv: string | null;
    sortPriority: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteProductVariantSubscription = {
  __typename: "ProductVariant";
  id: string;
  productID: string;
  name: string;
  code: string;
  description: string;
  price: number;
  inStock: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  product: {
    __typename: "Product";
    id: string;
    categoryID: string;
    name: string;
    slug: string;
    code: string;
    description: string;
    allergens: string | null;
    abv: string | null;
    sortPriority: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateOrderSubscription = {
  __typename: "Order";
  id: string;
  reference: string;
  updatedAt: string;
  createdAt: string;
  checkoutID: string;
  total: number;
  name: string;
  status: string;
  emailAddress: string;
  contactNumber: string | null;
  notes: string | null;
  deliveryAddressLine1: string | null;
  deliveryAddressLine2: string | null;
  deliveryTown: string | null;
  deliveryPostalCode: string | null;
  deliveryCountryCode: string | null;
  marketingAllowed: boolean;
  orderItems: {
    __typename: "ModelOrderItemConnection";
    nextToken: string | null;
  } | null;
};

export type OnUpdateOrderSubscription = {
  __typename: "Order";
  id: string;
  reference: string;
  updatedAt: string;
  createdAt: string;
  checkoutID: string;
  total: number;
  name: string;
  status: string;
  emailAddress: string;
  contactNumber: string | null;
  notes: string | null;
  deliveryAddressLine1: string | null;
  deliveryAddressLine2: string | null;
  deliveryTown: string | null;
  deliveryPostalCode: string | null;
  deliveryCountryCode: string | null;
  marketingAllowed: boolean;
  orderItems: {
    __typename: "ModelOrderItemConnection";
    nextToken: string | null;
  } | null;
};

export type OnDeleteOrderSubscription = {
  __typename: "Order";
  id: string;
  reference: string;
  updatedAt: string;
  createdAt: string;
  checkoutID: string;
  total: number;
  name: string;
  status: string;
  emailAddress: string;
  contactNumber: string | null;
  notes: string | null;
  deliveryAddressLine1: string | null;
  deliveryAddressLine2: string | null;
  deliveryTown: string | null;
  deliveryPostalCode: string | null;
  deliveryCountryCode: string | null;
  marketingAllowed: boolean;
  orderItems: {
    __typename: "ModelOrderItemConnection";
    nextToken: string | null;
  } | null;
};

export type OnCreateOrderItemSubscription = {
  __typename: "OrderItem";
  id: string;
  orderID: string;
  productVariantID: string;
  productName: string;
  productCode: string;
  productVariantName: string;
  productVariantCode: string;
  productVariantPrice: number;
  quantity: number;
  createdAt: string;
  updatedAt: string;
  productVariant: {
    __typename: "ProductVariant";
    id: string;
    productID: string;
    name: string;
    code: string;
    description: string;
    price: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  order: {
    __typename: "Order";
    id: string;
    reference: string;
    updatedAt: string;
    createdAt: string;
    checkoutID: string;
    total: number;
    name: string;
    status: string;
    emailAddress: string;
    contactNumber: string | null;
    notes: string | null;
    deliveryAddressLine1: string | null;
    deliveryAddressLine2: string | null;
    deliveryTown: string | null;
    deliveryPostalCode: string | null;
    deliveryCountryCode: string | null;
    marketingAllowed: boolean;
  } | null;
};

export type OnUpdateOrderItemSubscription = {
  __typename: "OrderItem";
  id: string;
  orderID: string;
  productVariantID: string;
  productName: string;
  productCode: string;
  productVariantName: string;
  productVariantCode: string;
  productVariantPrice: number;
  quantity: number;
  createdAt: string;
  updatedAt: string;
  productVariant: {
    __typename: "ProductVariant";
    id: string;
    productID: string;
    name: string;
    code: string;
    description: string;
    price: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  order: {
    __typename: "Order";
    id: string;
    reference: string;
    updatedAt: string;
    createdAt: string;
    checkoutID: string;
    total: number;
    name: string;
    status: string;
    emailAddress: string;
    contactNumber: string | null;
    notes: string | null;
    deliveryAddressLine1: string | null;
    deliveryAddressLine2: string | null;
    deliveryTown: string | null;
    deliveryPostalCode: string | null;
    deliveryCountryCode: string | null;
    marketingAllowed: boolean;
  } | null;
};

export type OnDeleteOrderItemSubscription = {
  __typename: "OrderItem";
  id: string;
  orderID: string;
  productVariantID: string;
  productName: string;
  productCode: string;
  productVariantName: string;
  productVariantCode: string;
  productVariantPrice: number;
  quantity: number;
  createdAt: string;
  updatedAt: string;
  productVariant: {
    __typename: "ProductVariant";
    id: string;
    productID: string;
    name: string;
    code: string;
    description: string;
    price: number;
    inStock: boolean;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  order: {
    __typename: "Order";
    id: string;
    reference: string;
    updatedAt: string;
    createdAt: string;
    checkoutID: string;
    total: number;
    name: string;
    status: string;
    emailAddress: string;
    contactNumber: string | null;
    notes: string | null;
    deliveryAddressLine1: string | null;
    deliveryAddressLine2: string | null;
    deliveryTown: string | null;
    deliveryPostalCode: string | null;
    deliveryCountryCode: string | null;
    marketingAllowed: boolean;
  } | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateCategory(
    input: CreateCategoryInput,
    condition?: ModelCategoryConditionInput
  ): Promise<CreateCategoryMutation> {
    const statement = `mutation CreateCategory($input: CreateCategoryInput!, $condition: ModelCategoryConditionInput) {
        createCategory(input: $input, condition: $condition) {
          __typename
          id
          name
          slug
          createdAt
          updatedAt
          products {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCategoryMutation>response.data.createCategory;
  }
  async UpdateCategory(
    input: UpdateCategoryInput,
    condition?: ModelCategoryConditionInput
  ): Promise<UpdateCategoryMutation> {
    const statement = `mutation UpdateCategory($input: UpdateCategoryInput!, $condition: ModelCategoryConditionInput) {
        updateCategory(input: $input, condition: $condition) {
          __typename
          id
          name
          slug
          createdAt
          updatedAt
          products {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCategoryMutation>response.data.updateCategory;
  }
  async DeleteCategory(
    input: DeleteCategoryInput,
    condition?: ModelCategoryConditionInput
  ): Promise<DeleteCategoryMutation> {
    const statement = `mutation DeleteCategory($input: DeleteCategoryInput!, $condition: ModelCategoryConditionInput) {
        deleteCategory(input: $input, condition: $condition) {
          __typename
          id
          name
          slug
          createdAt
          updatedAt
          products {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCategoryMutation>response.data.deleteCategory;
  }
  async CreateProduct(
    input: CreateProductInput,
    condition?: ModelProductConditionInput
  ): Promise<CreateProductMutation> {
    const statement = `mutation CreateProduct($input: CreateProductInput!, $condition: ModelProductConditionInput) {
        createProduct(input: $input, condition: $condition) {
          __typename
          id
          categoryID
          name
          slug
          code
          description
          allergens
          abv
          sortPriority
          inStock
          active
          createdAt
          updatedAt
          category {
            __typename
            id
            name
            slug
            createdAt
            updatedAt
          }
          productVariants {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProductMutation>response.data.createProduct;
  }
  async UpdateProduct(
    input: UpdateProductInput,
    condition?: ModelProductConditionInput
  ): Promise<UpdateProductMutation> {
    const statement = `mutation UpdateProduct($input: UpdateProductInput!, $condition: ModelProductConditionInput) {
        updateProduct(input: $input, condition: $condition) {
          __typename
          id
          categoryID
          name
          slug
          code
          description
          allergens
          abv
          sortPriority
          inStock
          active
          createdAt
          updatedAt
          category {
            __typename
            id
            name
            slug
            createdAt
            updatedAt
          }
          productVariants {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProductMutation>response.data.updateProduct;
  }
  async DeleteProduct(
    input: DeleteProductInput,
    condition?: ModelProductConditionInput
  ): Promise<DeleteProductMutation> {
    const statement = `mutation DeleteProduct($input: DeleteProductInput!, $condition: ModelProductConditionInput) {
        deleteProduct(input: $input, condition: $condition) {
          __typename
          id
          categoryID
          name
          slug
          code
          description
          allergens
          abv
          sortPriority
          inStock
          active
          createdAt
          updatedAt
          category {
            __typename
            id
            name
            slug
            createdAt
            updatedAt
          }
          productVariants {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProductMutation>response.data.deleteProduct;
  }
  async CreateProductVariant(
    input: CreateProductVariantInput,
    condition?: ModelProductVariantConditionInput
  ): Promise<CreateProductVariantMutation> {
    const statement = `mutation CreateProductVariant($input: CreateProductVariantInput!, $condition: ModelProductVariantConditionInput) {
        createProductVariant(input: $input, condition: $condition) {
          __typename
          id
          productID
          name
          code
          description
          price
          inStock
          active
          createdAt
          updatedAt
          product {
            __typename
            id
            categoryID
            name
            slug
            code
            description
            allergens
            abv
            sortPriority
            inStock
            active
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProductVariantMutation>response.data.createProductVariant;
  }
  async UpdateProductVariant(
    input: UpdateProductVariantInput,
    condition?: ModelProductVariantConditionInput
  ): Promise<UpdateProductVariantMutation> {
    const statement = `mutation UpdateProductVariant($input: UpdateProductVariantInput!, $condition: ModelProductVariantConditionInput) {
        updateProductVariant(input: $input, condition: $condition) {
          __typename
          id
          productID
          name
          code
          description
          price
          inStock
          active
          createdAt
          updatedAt
          product {
            __typename
            id
            categoryID
            name
            slug
            code
            description
            allergens
            abv
            sortPriority
            inStock
            active
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProductVariantMutation>response.data.updateProductVariant;
  }
  async DeleteProductVariant(
    input: DeleteProductVariantInput,
    condition?: ModelProductVariantConditionInput
  ): Promise<DeleteProductVariantMutation> {
    const statement = `mutation DeleteProductVariant($input: DeleteProductVariantInput!, $condition: ModelProductVariantConditionInput) {
        deleteProductVariant(input: $input, condition: $condition) {
          __typename
          id
          productID
          name
          code
          description
          price
          inStock
          active
          createdAt
          updatedAt
          product {
            __typename
            id
            categoryID
            name
            slug
            code
            description
            allergens
            abv
            sortPriority
            inStock
            active
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProductVariantMutation>response.data.deleteProductVariant;
  }
  async CreateOrder(
    input: CreateOrderInput,
    condition?: ModelOrderConditionInput
  ): Promise<CreateOrderMutation> {
    const statement = `mutation CreateOrder($input: CreateOrderInput!, $condition: ModelOrderConditionInput) {
        createOrder(input: $input, condition: $condition) {
          __typename
          id
          reference
          updatedAt
          createdAt
          checkoutID
          total
          name
          status
          emailAddress
          contactNumber
          notes
          deliveryAddressLine1
          deliveryAddressLine2
          deliveryTown
          deliveryPostalCode
          deliveryCountryCode
          marketingAllowed
          orderItems {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderMutation>response.data.createOrder;
  }
  async UpdateOrder(
    input: UpdateOrderInput,
    condition?: ModelOrderConditionInput
  ): Promise<UpdateOrderMutation> {
    const statement = `mutation UpdateOrder($input: UpdateOrderInput!, $condition: ModelOrderConditionInput) {
        updateOrder(input: $input, condition: $condition) {
          __typename
          id
          reference
          updatedAt
          createdAt
          checkoutID
          total
          name
          status
          emailAddress
          contactNumber
          notes
          deliveryAddressLine1
          deliveryAddressLine2
          deliveryTown
          deliveryPostalCode
          deliveryCountryCode
          marketingAllowed
          orderItems {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderMutation>response.data.updateOrder;
  }
  async DeleteOrder(
    input: DeleteOrderInput,
    condition?: ModelOrderConditionInput
  ): Promise<DeleteOrderMutation> {
    const statement = `mutation DeleteOrder($input: DeleteOrderInput!, $condition: ModelOrderConditionInput) {
        deleteOrder(input: $input, condition: $condition) {
          __typename
          id
          reference
          updatedAt
          createdAt
          checkoutID
          total
          name
          status
          emailAddress
          contactNumber
          notes
          deliveryAddressLine1
          deliveryAddressLine2
          deliveryTown
          deliveryPostalCode
          deliveryCountryCode
          marketingAllowed
          orderItems {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderMutation>response.data.deleteOrder;
  }
  async CreateOrderItem(
    input: CreateOrderItemInput,
    condition?: ModelOrderItemConditionInput
  ): Promise<CreateOrderItemMutation> {
    const statement = `mutation CreateOrderItem($input: CreateOrderItemInput!, $condition: ModelOrderItemConditionInput) {
        createOrderItem(input: $input, condition: $condition) {
          __typename
          id
          orderID
          productVariantID
          productName
          productCode
          productVariantName
          productVariantCode
          productVariantPrice
          quantity
          createdAt
          updatedAt
          productVariant {
            __typename
            id
            productID
            name
            code
            description
            price
            inStock
            active
            createdAt
            updatedAt
          }
          order {
            __typename
            id
            reference
            updatedAt
            createdAt
            checkoutID
            total
            name
            status
            emailAddress
            contactNumber
            notes
            deliveryAddressLine1
            deliveryAddressLine2
            deliveryTown
            deliveryPostalCode
            deliveryCountryCode
            marketingAllowed
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrderItemMutation>response.data.createOrderItem;
  }
  async UpdateOrderItem(
    input: UpdateOrderItemInput,
    condition?: ModelOrderItemConditionInput
  ): Promise<UpdateOrderItemMutation> {
    const statement = `mutation UpdateOrderItem($input: UpdateOrderItemInput!, $condition: ModelOrderItemConditionInput) {
        updateOrderItem(input: $input, condition: $condition) {
          __typename
          id
          orderID
          productVariantID
          productName
          productCode
          productVariantName
          productVariantCode
          productVariantPrice
          quantity
          createdAt
          updatedAt
          productVariant {
            __typename
            id
            productID
            name
            code
            description
            price
            inStock
            active
            createdAt
            updatedAt
          }
          order {
            __typename
            id
            reference
            updatedAt
            createdAt
            checkoutID
            total
            name
            status
            emailAddress
            contactNumber
            notes
            deliveryAddressLine1
            deliveryAddressLine2
            deliveryTown
            deliveryPostalCode
            deliveryCountryCode
            marketingAllowed
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrderItemMutation>response.data.updateOrderItem;
  }
  async DeleteOrderItem(
    input: DeleteOrderItemInput,
    condition?: ModelOrderItemConditionInput
  ): Promise<DeleteOrderItemMutation> {
    const statement = `mutation DeleteOrderItem($input: DeleteOrderItemInput!, $condition: ModelOrderItemConditionInput) {
        deleteOrderItem(input: $input, condition: $condition) {
          __typename
          id
          orderID
          productVariantID
          productName
          productCode
          productVariantName
          productVariantCode
          productVariantPrice
          quantity
          createdAt
          updatedAt
          productVariant {
            __typename
            id
            productID
            name
            code
            description
            price
            inStock
            active
            createdAt
            updatedAt
          }
          order {
            __typename
            id
            reference
            updatedAt
            createdAt
            checkoutID
            total
            name
            status
            emailAddress
            contactNumber
            notes
            deliveryAddressLine1
            deliveryAddressLine2
            deliveryTown
            deliveryPostalCode
            deliveryCountryCode
            marketingAllowed
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrderItemMutation>response.data.deleteOrderItem;
  }
  async ListCategorys(
    filter?: ModelCategoryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCategorysQuery> {
    const statement = `query ListCategorys($filter: ModelCategoryFilterInput, $limit: Int, $nextToken: String) {
        listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            slug
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCategorysQuery>response.data.listCategorys;
  }
  async GetCategory(id: string): Promise<GetCategoryQuery> {
    const statement = `query GetCategory($id: ID!) {
        getCategory(id: $id) {
          __typename
          id
          name
          slug
          createdAt
          updatedAt
          products {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCategoryQuery>response.data.getCategory;
  }
  async ListProducts(
    filter?: ModelProductFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProductsQuery> {
    const statement = `query ListProducts($filter: ModelProductFilterInput, $limit: Int, $nextToken: String) {
        listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            categoryID
            name
            slug
            code
            description
            allergens
            abv
            sortPriority
            inStock
            active
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProductsQuery>response.data.listProducts;
  }
  async GetProduct(id: string): Promise<GetProductQuery> {
    const statement = `query GetProduct($id: ID!) {
        getProduct(id: $id) {
          __typename
          id
          categoryID
          name
          slug
          code
          description
          allergens
          abv
          sortPriority
          inStock
          active
          createdAt
          updatedAt
          category {
            __typename
            id
            name
            slug
            createdAt
            updatedAt
          }
          productVariants {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProductQuery>response.data.getProduct;
  }
  async ListProductVariants(
    filter?: ModelProductVariantFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProductVariantsQuery> {
    const statement = `query ListProductVariants($filter: ModelProductVariantFilterInput, $limit: Int, $nextToken: String) {
        listProductVariants(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            productID
            name
            code
            description
            price
            inStock
            active
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProductVariantsQuery>response.data.listProductVariants;
  }
  async GetProductVariant(id: string): Promise<GetProductVariantQuery> {
    const statement = `query GetProductVariant($id: ID!) {
        getProductVariant(id: $id) {
          __typename
          id
          productID
          name
          code
          description
          price
          inStock
          active
          createdAt
          updatedAt
          product {
            __typename
            id
            categoryID
            name
            slug
            code
            description
            allergens
            abv
            sortPriority
            inStock
            active
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProductVariantQuery>response.data.getProductVariant;
  }
  async ListOrders(
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOrdersQuery> {
    const statement = `query ListOrders($filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
        listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            reference
            updatedAt
            createdAt
            checkoutID
            total
            name
            status
            emailAddress
            contactNumber
            notes
            deliveryAddressLine1
            deliveryAddressLine2
            deliveryTown
            deliveryPostalCode
            deliveryCountryCode
            marketingAllowed
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrdersQuery>response.data.listOrders;
  }
  async GetOrder(id: string): Promise<GetOrderQuery> {
    const statement = `query GetOrder($id: ID!) {
        getOrder(id: $id) {
          __typename
          id
          reference
          updatedAt
          createdAt
          checkoutID
          total
          name
          status
          emailAddress
          contactNumber
          notes
          deliveryAddressLine1
          deliveryAddressLine2
          deliveryTown
          deliveryPostalCode
          deliveryCountryCode
          marketingAllowed
          orderItems {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrderQuery>response.data.getOrder;
  }
  async OrdersByReference(
    reference?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OrdersByReferenceQuery> {
    const statement = `query OrdersByReference($reference: String, $sortDirection: ModelSortDirection, $filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
        ordersByReference(reference: $reference, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            reference
            updatedAt
            createdAt
            checkoutID
            total
            name
            status
            emailAddress
            contactNumber
            notes
            deliveryAddressLine1
            deliveryAddressLine2
            deliveryTown
            deliveryPostalCode
            deliveryCountryCode
            marketingAllowed
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (reference) {
      gqlAPIServiceArguments.reference = reference;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OrdersByReferenceQuery>response.data.ordersByReference;
  }
  async OrdersByStatus(
    status?: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelOrderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OrdersByStatusQuery> {
    const statement = `query OrdersByStatus($status: String, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelOrderFilterInput, $limit: Int, $nextToken: String) {
        ordersByStatus(status: $status, createdAt: $createdAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            reference
            updatedAt
            createdAt
            checkoutID
            total
            name
            status
            emailAddress
            contactNumber
            notes
            deliveryAddressLine1
            deliveryAddressLine2
            deliveryTown
            deliveryPostalCode
            deliveryCountryCode
            marketingAllowed
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <OrdersByStatusQuery>response.data.ordersByStatus;
  }
  async GetOrderItem(id: string): Promise<GetOrderItemQuery> {
    const statement = `query GetOrderItem($id: ID!) {
        getOrderItem(id: $id) {
          __typename
          id
          orderID
          productVariantID
          productName
          productCode
          productVariantName
          productVariantCode
          productVariantPrice
          quantity
          createdAt
          updatedAt
          productVariant {
            __typename
            id
            productID
            name
            code
            description
            price
            inStock
            active
            createdAt
            updatedAt
          }
          order {
            __typename
            id
            reference
            updatedAt
            createdAt
            checkoutID
            total
            name
            status
            emailAddress
            contactNumber
            notes
            deliveryAddressLine1
            deliveryAddressLine2
            deliveryTown
            deliveryPostalCode
            deliveryCountryCode
            marketingAllowed
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrderItemQuery>response.data.getOrderItem;
  }
  async ListOrderItems(
    filter?: ModelOrderItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOrderItemsQuery> {
    const statement = `query ListOrderItems($filter: ModelOrderItemFilterInput, $limit: Int, $nextToken: String) {
        listOrderItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            orderID
            productVariantID
            productName
            productCode
            productVariantName
            productVariantCode
            productVariantPrice
            quantity
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrderItemsQuery>response.data.listOrderItems;
  }
  OnCreateCategoryListener: Observable<
    SubscriptionResponse<OnCreateCategorySubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCategory {
        onCreateCategory {
          __typename
          id
          name
          slug
          createdAt
          updatedAt
          products {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateCategorySubscription>>;

  OnUpdateCategoryListener: Observable<
    SubscriptionResponse<OnUpdateCategorySubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCategory {
        onUpdateCategory {
          __typename
          id
          name
          slug
          createdAt
          updatedAt
          products {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateCategorySubscription>>;

  OnDeleteCategoryListener: Observable<
    SubscriptionResponse<OnDeleteCategorySubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCategory {
        onDeleteCategory {
          __typename
          id
          name
          slug
          createdAt
          updatedAt
          products {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteCategorySubscription>>;

  OnCreateProductListener: Observable<
    SubscriptionResponse<OnCreateProductSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProduct {
        onCreateProduct {
          __typename
          id
          categoryID
          name
          slug
          code
          description
          allergens
          abv
          sortPriority
          inStock
          active
          createdAt
          updatedAt
          category {
            __typename
            id
            name
            slug
            createdAt
            updatedAt
          }
          productVariants {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateProductSubscription>>;

  OnUpdateProductListener: Observable<
    SubscriptionResponse<OnUpdateProductSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateProduct {
        onUpdateProduct {
          __typename
          id
          categoryID
          name
          slug
          code
          description
          allergens
          abv
          sortPriority
          inStock
          active
          createdAt
          updatedAt
          category {
            __typename
            id
            name
            slug
            createdAt
            updatedAt
          }
          productVariants {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateProductSubscription>>;

  OnDeleteProductListener: Observable<
    SubscriptionResponse<OnDeleteProductSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteProduct {
        onDeleteProduct {
          __typename
          id
          categoryID
          name
          slug
          code
          description
          allergens
          abv
          sortPriority
          inStock
          active
          createdAt
          updatedAt
          category {
            __typename
            id
            name
            slug
            createdAt
            updatedAt
          }
          productVariants {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteProductSubscription>>;

  OnCreateProductVariantListener: Observable<
    SubscriptionResponse<OnCreateProductVariantSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProductVariant {
        onCreateProductVariant {
          __typename
          id
          productID
          name
          code
          description
          price
          inStock
          active
          createdAt
          updatedAt
          product {
            __typename
            id
            categoryID
            name
            slug
            code
            description
            allergens
            abv
            sortPriority
            inStock
            active
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateProductVariantSubscription>>;

  OnUpdateProductVariantListener: Observable<
    SubscriptionResponse<OnUpdateProductVariantSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateProductVariant {
        onUpdateProductVariant {
          __typename
          id
          productID
          name
          code
          description
          price
          inStock
          active
          createdAt
          updatedAt
          product {
            __typename
            id
            categoryID
            name
            slug
            code
            description
            allergens
            abv
            sortPriority
            inStock
            active
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateProductVariantSubscription>>;

  OnDeleteProductVariantListener: Observable<
    SubscriptionResponse<OnDeleteProductVariantSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteProductVariant {
        onDeleteProductVariant {
          __typename
          id
          productID
          name
          code
          description
          price
          inStock
          active
          createdAt
          updatedAt
          product {
            __typename
            id
            categoryID
            name
            slug
            code
            description
            allergens
            abv
            sortPriority
            inStock
            active
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteProductVariantSubscription>>;

  OnCreateOrderListener: Observable<
    SubscriptionResponse<OnCreateOrderSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateOrder {
        onCreateOrder {
          __typename
          id
          reference
          updatedAt
          createdAt
          checkoutID
          total
          name
          status
          emailAddress
          contactNumber
          notes
          deliveryAddressLine1
          deliveryAddressLine2
          deliveryTown
          deliveryPostalCode
          deliveryCountryCode
          marketingAllowed
          orderItems {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateOrderSubscription>>;

  OnUpdateOrderListener: Observable<
    SubscriptionResponse<OnUpdateOrderSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateOrder {
        onUpdateOrder {
          __typename
          id
          reference
          updatedAt
          createdAt
          checkoutID
          total
          name
          status
          emailAddress
          contactNumber
          notes
          deliveryAddressLine1
          deliveryAddressLine2
          deliveryTown
          deliveryPostalCode
          deliveryCountryCode
          marketingAllowed
          orderItems {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateOrderSubscription>>;

  OnDeleteOrderListener: Observable<
    SubscriptionResponse<OnDeleteOrderSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteOrder {
        onDeleteOrder {
          __typename
          id
          reference
          updatedAt
          createdAt
          checkoutID
          total
          name
          status
          emailAddress
          contactNumber
          notes
          deliveryAddressLine1
          deliveryAddressLine2
          deliveryTown
          deliveryPostalCode
          deliveryCountryCode
          marketingAllowed
          orderItems {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteOrderSubscription>>;

  OnCreateOrderItemListener: Observable<
    SubscriptionResponse<OnCreateOrderItemSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateOrderItem {
        onCreateOrderItem {
          __typename
          id
          orderID
          productVariantID
          productName
          productCode
          productVariantName
          productVariantCode
          productVariantPrice
          quantity
          createdAt
          updatedAt
          productVariant {
            __typename
            id
            productID
            name
            code
            description
            price
            inStock
            active
            createdAt
            updatedAt
          }
          order {
            __typename
            id
            reference
            updatedAt
            createdAt
            checkoutID
            total
            name
            status
            emailAddress
            contactNumber
            notes
            deliveryAddressLine1
            deliveryAddressLine2
            deliveryTown
            deliveryPostalCode
            deliveryCountryCode
            marketingAllowed
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateOrderItemSubscription>>;

  OnUpdateOrderItemListener: Observable<
    SubscriptionResponse<OnUpdateOrderItemSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateOrderItem {
        onUpdateOrderItem {
          __typename
          id
          orderID
          productVariantID
          productName
          productCode
          productVariantName
          productVariantCode
          productVariantPrice
          quantity
          createdAt
          updatedAt
          productVariant {
            __typename
            id
            productID
            name
            code
            description
            price
            inStock
            active
            createdAt
            updatedAt
          }
          order {
            __typename
            id
            reference
            updatedAt
            createdAt
            checkoutID
            total
            name
            status
            emailAddress
            contactNumber
            notes
            deliveryAddressLine1
            deliveryAddressLine2
            deliveryTown
            deliveryPostalCode
            deliveryCountryCode
            marketingAllowed
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateOrderItemSubscription>>;

  OnDeleteOrderItemListener: Observable<
    SubscriptionResponse<OnDeleteOrderItemSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteOrderItem {
        onDeleteOrderItem {
          __typename
          id
          orderID
          productVariantID
          productName
          productCode
          productVariantName
          productVariantCode
          productVariantPrice
          quantity
          createdAt
          updatedAt
          productVariant {
            __typename
            id
            productID
            name
            code
            description
            price
            inStock
            active
            createdAt
            updatedAt
          }
          order {
            __typename
            id
            reference
            updatedAt
            createdAt
            checkoutID
            total
            name
            status
            emailAddress
            contactNumber
            notes
            deliveryAddressLine1
            deliveryAddressLine2
            deliveryTown
            deliveryPostalCode
            deliveryCountryCode
            marketingAllowed
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteOrderItemSubscription>>;
}
