import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutService } from '../checkout.service';
import { Order } from '../../models/index';

declare const SumUpCard: any;

@Component({
  selector: 'app-take-payment',
  templateUrl: './take-payment.component.html',
  styleUrls: ['./take-payment.component.scss'],
})
export class TakePaymentComponent implements OnInit {
  @Input() order: Order;
  pending: Boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private checkoutService: CheckoutService
  ) {}

  ngOnInit(): void {
    SumUpCard.mount({
      checkoutId: this.order.checkoutID,
      onResponse: (type, body) => {
        if (type == 'success') {
          if (body.status == 'PAID') {
            this.activeModal.close();
          } else if (body.status == 'PENDING') {
            this.waitForPayment();
          } else {
            this.activeModal.dismiss('Transaction failed');
          }
        }
      },
    });
  }

  waitForPayment(): void {
    this.pending = true;

    this.checkoutService
      .checkPaymentStatus(this.order.reference)
      .then((status) => {
        if (status == 'PAID') {
          this.activeModal.close();
        } else if (status == 'PENDING') {
          setTimeout(() => {
            this.waitForPayment();
          }, 5000);
        } else {
          this.activeModal.dismiss('Transaction failed');
        }
      });
  }
}
