<nav *ngIf="product && productID" aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/admin/products">Products</a>
    </li>
    <li class="breadcrumb-item">
      <a [routerLink]="['/admin/products/update', productID]">{{ product.name }}</a>
    </li>
    <li class="breadcrumb-item" active>Variants</li>
  </ol>
</nav>

<a class="btn btn-primary btn-sm" routerLink="create">New Variant</a>

<br /><br />

<table *ngIf="productVariants; else loading" class="table">
  <thead>
    <tr>
      <th>Code</th>
      <th>Name</th>
      <th>Price</th>
      <th>In Stock</th>
      <th>Active</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let variant of productVariants">
      <td>{{ variant.code }}</td>
      <td>{{ variant.name }}</td>
      <td>{{ variant.price | number: '1.2-2' }} GBP</td>
      <td>{{ variant.inStock ? 'Yes' : 'No' }}</td>
      <td>{{ variant.active ? 'Yes' : 'No' }}</td>
      <td>
        <a class="btn btn-sm btn-primary" [routerLink]="['update', variant.id]">Update</a>
        &nbsp;
        <button (click)="delete(variant.id)" type="button" class="btn btn-sm btn-danger">Delete</button>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #loading>
  Loading...
</ng-template>
