import { Component, OnInit } from '@angular/core';

import Amplify from 'aws-amplify';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { ShoppingCart, ShoppingCartService } from './shopping-cart.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  cart: ShoppingCart | null = null;
  itemCount: number = 0;

  constructor(private shoppingCart: ShoppingCartService) { }

  ngOnInit(): void {
    this.shoppingCart.onUpdate((cart) => {
      this.cart = cart;
      this.itemCount = Object.values(this.cart).reduce((count, total) => count + total, 0);
    });

    onAuthUIStateChange(authState => {
      if (authState == "signedin") {
        Amplify.configure({
          'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS'
        });
      } else {
        Amplify.configure({
          'aws_appsync_authenticationType': 'AWS_IAM'
        });
      }
    });
  }

}
