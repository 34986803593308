import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';

import { APIService, ModelSortDirection } from '../../API.service';
import { Order } from '../../models/index';
import { CheckoutService } from '../checkout.service';

@Component({
  selector: 'app-admin-orders',
  templateUrl: './admin-orders.component.html',
  styleUrls: ['./admin-orders.component.scss'],
})
export class AdminOrdersComponent implements OnInit {
  orders: Array<Order> | null;
  status: string = 'PAID';
  nextToken: string | null = null;
  orderCache: Array<Array<Order>> = [];
  pageIndex: number = null;

  constructor(
    private ref: ChangeDetectorRef,
    private api: APIService,
    private checkoutService: CheckoutService
  ) {}

  ngOnInit(): void {
    this.clearOrders();
    this.updateOrders();

    onAuthUIStateChange((authState, authData) => {
      if (authState === 'signedin') {
        this.clearOrders();
        this.updateOrders();
      }
    });
  }

  clearOrders() {
    this.orderCache = [];
    this.pageIndex = 0;
    this.nextToken = null;
  }

  changeFilter() {
    this.clearOrders();
    this.updateOrders();
  }

  updateOrders() {
    this.orders = null;

    if (this.pageIndex >= this.orderCache.length) {
      this.api
        .OrdersByStatus(
          this.status,
          null,
          ModelSortDirection.DESC,
          null,
          10,
          this.nextToken
        )
        .then((result) => {
          this.orders = result.items;
          this.orderCache.push(result.items);
          this.nextToken = result.nextToken;
          this.ref.detectChanges();
        });
    } else {
      this.orders = this.orderCache[this.pageIndex];
    }
  }

  next() {
    this.pageIndex++;
    this.updateOrders();
  }

  canNext() {
    return (
      this.nextToken !== null || this.pageIndex < this.orderCache.length - 1
    );
  }

  prev() {
    this.pageIndex--;

    if (this.pageIndex < 0) {
      this.pageIndex = 0;
    }

    this.updateOrders();
  }

  canPrev() {
    return this.pageIndex > 0;
  }

  page() {
    return 1 + this.pageIndex;
  }

  readyForCollection(order) {
    this.checkoutService
      .markReadyForCollection(order.reference)
      .then(() => {
        this.clearOrders();
        this.updateOrders();
      })
      .catch((error) => {
        alert('Error: ' + error);
      });
  }

  checkStatus(order) {
    this.checkoutService.checkPaymentStatus(order.reference).then((status) => {
      if (status == 'PAID') {
        this.checkoutService.completeOrder(order.reference).then(() => {
          this.clearOrders();
          this.updateOrders();
          alert('Order was paid');
        });
      } else if (status == 'PENDING') {
        alert('Order is still pending');
      } else if (status == 'FAILED') {
        this.checkoutService.cancelOrder(order.reference).then(() => {
          this.clearOrders();
          this.updateOrders();
          alert('Order had failed and is now cancelled');
        });
      }
    });
  }
}
