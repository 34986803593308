<amplify-authenticator>
  <amplify-sign-in
  header-text="Administrator Login"
  slot="sign-in"
  hide-sign-up="true"
  ></amplify-sign-in>

  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" routerLink="products" routerLinkActive="active">Products</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="categories" routerLinkActive="active">Categories</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="orders" routerLinkActive="active">Orders</a>
    </li>
  </ul>
  
  <br />
  
  <router-outlet></router-outlet>

  <hr />

  <div class="d-flex justify-content-end">
    <amplify-sign-out></amplify-sign-out>
  </div>
</amplify-authenticator>
