<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/">Home</a>
    </li>
    <li class="breadcrumb-item active">Shop</li>
  </ol>
</nav>

<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
  <div *ngFor="let product of products" class="col mb-4">
    <div class="card h-100">
      <div class="card-img-top">
        <a [routerLink]="['/shop/product', product.id]">
          <amplify-s3-image [imgKey]="product.id"></amplify-s3-image>
        </a>
      </div>

      <div class="card-body">
        <h5 class="card-title">{{ product.name }}</h5>
        <p class="card-text">{{ product.description }}</p>
      </div>
      <div class="card-footer">
        <a class="btn btn-primary" [routerLink]="['/shop/product', product.id]">View Product</a>

        <span *ngIf="!product.inStock" style="color: red; font-weight: 600;">
          &nbsp;
          Out of Stock
        </span>
      </div>
    </div>
  </div>
</div>