<div *ngIf="product; else loading">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/shop">Shop</a>
      </li>
      <li class="breadcrumb-item active">{{ product.name }}</li>
    </ol>
  </nav>

  <div class="row">
    <div class="col col-md-4">
      <div class="product-image">
        <amplify-s3-image [imgKey]="product.id"></amplify-s3-image>
      </div>
    </div>
    <div class="col col-md-4">
      <h4>{{ product.name }}</h4>
      <p>{{ product.description }}</p>
    </div>
    <div class="col col-md-4">
      <select class="form-control" [disabled]="!selectedProductVariant.inStock" [(ngModel)]="selectedProductVariant">
        <option *ngFor="let variant of productVariants" [ngValue]="variant" [disabled]="!variant.inStock">
          {{ variant.name }}
        </option>
      </select>

      <br />

      <table class="table table-striped">
        <tr>
          <th>Availability</th>
          <td *ngIf="product.inStock && selectedProductVariant.inStock; else outOfStock">
            In Stock
          </td>
          <ng-template #outOfStock>
            <td>Out of Stock</td>
          </ng-template>
        </tr>
        <tr>
          <th>Information</th>
          <td>
          {{ selectedProductVariant.description }}
          </td>
        </tr>
        <tr *ngIf="product.allergens">
          <th>Allergens</th>
          <td>
          {{ product.allergens }}
          </td>
        </tr>
        <tr *ngIf="product.abv">
          <th>ABV</th>
          <td>
          {{ product.abv }}%
          </td>
        </tr>
        <tr>
          <th>Price</th>
          <td>
            £{{ selectedProductVariant.price | number: '1.2-2' }}
          </td>
        </tr>
        <tr>
          <th>Quantity</th>
          <td>
            <input type="number" step="1" (change)="filterQuantityToInt()" [(ngModel)]="quantity" min="1" />
          </td>
        </tr>
        <tr>
          <th>Total Price</th>
          <td>
            £{{ selectedProductVariant.price * quantity | number: '1.2-2' }}
          </td>
        </tr>
      </table>

      <button [disabled]="!product.inStock || !selectedProductVariant.inStock" class="btn float-right" [class]="{ 'btn-primary': !added, 'btn-success': added, 'btn-danger': !product.inStock || !selectedProductVariant.inStock }" (click)="addToCart()">
        <i class="fa fa-plus"></i>
        &nbsp;
        Add to Cart
      </button>

      <br /><br />

      <button [disabled]="!product.inStock || !selectedProductVariant.inStock" class="btn btn-primary float-right" [class]="{ 'btn-danger': !product.inStock || !selectedProductVariant.inStock }" (click)="addToCartCheckout()">
        <i class="fa fa-shopping-cart"></i>
        &nbsp;
        Add and Checkout
      </button>
    </div>
  </div>
</div>

<ng-template #loading>
  Loading...
</ng-template>
