
<div class="row">
  <div class="col mb-3">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">
          <i class="fa fa-laptop"></i>
          &nbsp;Return to <a href="http://www.bondbrews.co.uk/">www.bondbrews.co.uk</a>
        </h4>
      </div>
    </div>
    <br />
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">
          <i class="fa fa-map"></i>
          &nbsp;
          Our Brewery Shop
        </h4>

        <br />

        <div class="row">
          <div class="col col-sm-12 col-md-6 mb-3">
            <p>
              Bond Brews Ltd<br />
              Unit 3 & 4<br />
              South Barns<br />
              Gardeners Green Farm<br />
              Heathlands Road<br />
              Wokingham<br />
              RG40 3AS
            </p>
          </div>
          <div class="col col-sm-12 col-md-6 mb-3">
            <p>
              Our shop is open on Monday, Wednesday and Friday between 11am and 5pm
            </p>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">
          <i class="fa fa-phone"></i>
          &nbsp;Or Call Us: +44 1344 775450
        </h4>
      </div>
    </div>
  </div>
  <div class="col mb-3">
    <iframe
      width="600"
      height="450"
      frameborder="0" style="border:0"
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDICfiXeWzK3qqebZaw2eUxVohS_tCFQQY
        &q=Bond+Brews,Wokingham" allowfullscreen>
    </iframe>     
  </div>
</div>
