import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { APIService } from '../../API.service';
import { Category } from '../../models/index';

@Component({
  selector: 'app-admin-update-product',
  templateUrl: './admin-update-product.component.html',
  styleUrls: ['./admin-update-product.component.scss']
})
export class AdminUpdateProductComponent implements OnInit {

  form: FormGroup;
  loaded: boolean = false;
  categories: Array<Category> | null;

  constructor(private route: ActivatedRoute, private router: Router, private api: APIService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(null),
      slug: new FormControl(null),
      name: new FormControl(null),
      categoryID: new FormControl(null),
      code: new FormControl(null),
      description: new FormControl(null),
      allergens: new FormControl(null),
      abv: new FormControl(null),
      sortPriority: new FormControl(null),
      inStock: new FormControl(null),
      active: new FormControl(null)
    });

    this.route.params.subscribe(params => {
      this.api.GetProduct(params['productID']).then(product => {
        this.form.patchValue({
          id: product.id,
          name: product.name,
          slug: product.slug,
          categoryID: product.categoryID,
          code: product.code,
          description: product.description,
          allergens: product.allergens,
          abv: product.abv,
          sortPriority: product.sortPriority,
          inStock: product.inStock,
          active: product.active
        });

        this.loaded = true;
      });
    });

    this.api.ListCategorys().then(result => {
      this.categories = result.items;
    })
  }

  save() {
    this.api.UpdateProduct(this.form.value).then(() => {
      this.router.navigate(['admin/products']);
    });
  }

}
