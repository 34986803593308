import { Injectable } from '@angular/core';

interface ProductVariantTemplate {
  code: string;
  name: string;
  price: number;
  description: string;
  inStock: boolean;
  active: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ProductVariantPresetsService {

  public productVariants: Array<ProductVariantTemplate> = [
    {
      code: "500ML",
      name: "500ml Bottle",
      price: 3.00,
      description: "Our bottles are vegan friendly.",
      inStock: true,
      active: true
    },
    {
      code: "3L",
      name: "4.5pt / 3L Bag-in-Box",
      price: 13.00,
      description: "Our Bag-in-Box (polypin) beers will last for 2 weeks after opening.",
      inStock: true,
      active: true
    },
    {
      code: "5L",
      name: "9pt / 5L Mini-Cask",
      price: 25.00,
      description: "Our Mini-Cask beers will last up to 3 days after opening.",
      inStock: true,
      active: true
    },
    {
      code: "10L",
      name: "18pt / 10L Bag-in-Box",
      price: 39.00,
      description: "Our Bag-in-Box (polypin) beers will last for 2 weeks after opening.",
      inStock: true,
      active: true
    },
    {
      code: "20L",
      name: "36pt / 20L Bag-in-Box",
      price: 69.00,
      description: "Our Bag-in-Box (polypin) beers will last for 2 weeks after opening.",
      inStock: true,
      active: true
    }
  ];

}
