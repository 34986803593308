import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Hub } from 'aws-amplify';
import { AmplifyS3ImagePicker } from '@aws-amplify/ui-angular';

import { Product } from '../../models/index';

@Component({
  selector: 'app-admin-update-product-image',
  templateUrl: './admin-update-product-image.component.html',
  styleUrls: ['./admin-update-product-image.component.scss']
})
export class AdminUpdateProductImageComponent implements OnInit, AfterViewInit {

  @ViewChild(AmplifyS3ImagePicker) imagePicker: AmplifyS3ImagePicker;
  @Input() product: Product;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    Hub.listen('storage', payload => {
      if (payload.payload.event == "upload") {
        this.activeModal.dismiss('Image Uploaded');
      }
    });
  }

  ngAfterViewInit(): void {
    this.imagePicker.fileToKey = (data => {
      return this.product.id;
    });
  }

}
