import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { APIService } from '../../API.service';
import { Category } from '../../models/index';


@Component({
  selector: 'app-admin-create-product',
  templateUrl: './admin-create-product.component.html',
  styleUrls: ['./admin-create-product.component.scss']
})
export class AdminCreateProductComponent implements OnInit {

  form: FormGroup;
  categories: Array<Category> | null;

  constructor(private router: Router, private api: APIService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      slug: new FormControl(null),
      name: new FormControl(null),
      categoryID: new FormControl(null),
      code: new FormControl(null),
      description: new FormControl(null),
      allergens: new FormControl(null),
      abv: new FormControl(null),
      sortPriority: new FormControl(null),
      inStock: new FormControl(null),
      active: new FormControl(null)
    });

    this.api.ListCategorys().then(result => {
      this.categories = result.items;
    });
  }

  create() {
    if (!this.form.value.inStock) {
      this.form.patchValue({ inStock: false });
    }

    if (!this.form.value.active) {
      this.form.patchValue({ active: false });
    }

    this.api.CreateProduct(this.form.value).then(() => {
      this.router.navigate(['admin/products']);
    });
  }
}
