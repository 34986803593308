<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/admin/products">Products</a>
    </li>
    <li class="breadcrumb-item">
      <a *ngIf="product" [routerLink]="['/admin/products/update', productID]">{{ product.name }}</a>
    </li>
    <li class="breadcrumb-item">
      <a [routerLink]="['/admin/products/variants', productID]">Variants</a>
    </li>
    <li class="breadcrumb-item" active>{{ form.get('name').value }}</li>
  </ol>
</nav>

<form [formGroup]="form">
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="name">Name</label>
      <div class="input-group">
        <input formControlName="name" type="text" class="form-control" required />
        <div class="input-group-append" ngbDropdown #myDrop="ngbDropdown">
          <button class="btn btn-outline-secondary dropdown-toggle" type="button" ngbDropdownAnchor (focus)="myDrop.open()">
            Use Template
          </button>
          <div ngbDropdownMenu class="dropdown-menu">
            <button *ngFor="let template of productVariantPresets.productVariants" (click)="selectTemplate(template)" ngbDropdownItem class="dropdown-item">
              {{ template.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="code">Code</label>
      <input formControlName="code" type="text" class="form-control" required />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="price">Price</label>
      <input formControlName="price" type="number" step="0.01" class="form-control" required />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="description">Description</label>
      <textarea formControlName="description" class="form-control"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="inStock" />
        <label for="inStock" class="form-check-label">In Stock</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="active" />
        <label for="active" class="form-check-label">Active</label>
      </div>
    </div>
  </div>
  <button class="btn btn-primary btn-sm" (click)="create()">
    Create
  </button>
</form>