<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/admin/categories">Categories</a>
    </li>
    <li class="breadcrumb-item active">{{ form.get('name').value }}</li>
  </ol>
</nav>

<form *ngIf="loaded; else loading" [formGroup]="form">
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="slug">Slug</label>
      <input formControlName="slug" type="text" class="form-control" required />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="name">Name</label>
      <input formControlName="name" type="text" class="form-control" required />
    </div>
  </div>
  <button class="btn btn-primary btn-sm" (click)="save()">
    Save
  </button>
</form>

<ng-template #loading>
  <p>Loading...</p>
</ng-template>
