<div
  class="btn-group btn-group-toggle"
  (change)="changeFilter()"
  ngbRadioGroup
  [(ngModel)]="status"
>
  <label class="btn-primary" ngbButtonLabel>
    <input ngbButton type="radio" value="PAID" />
    Confirmed
  </label>
  <label class="btn-primary" ngbButtonLabel>
    <input ngbButton type="radio" value="READY_FOR_COLLECTION" />
    Ready For Collection
  </label>
  <label class="btn-primary" ngbButtonLabel>
    <input ngbButton type="radio" value="PENDING" />
    Pending
  </label>
  <label class="btn-primary" ngbButtonLabel>
    <input ngbButton type="radio" value="CANCELLED" />
    Cancelled
  </label>
</div>

<br /><br />

<table class="table">
  <thead>
    <tr>
      <th>Reference</th>
      <th>Name</th>
      <th>Contact Details</th>
      <th>Status</th>
      <th>Time</th>
      <th>Total</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let order of orders">
      <td>{{ order.reference }}</td>
      <td>{{ order.name }}</td>
      <td>
        {{ order.emailAddress }}<br />
        {{ order.contactNumber }}
      </td>
      <td>{{ order.status }}</td>
      <td>{{ order.createdAt | date: "medium" }}</td>
      <td>£{{ order.total | number: "1.2-2" }}</td>
      <td style="width: 1px; white-space: nowrap">
        <button
          *ngIf="order.status == 'PAID'"
          (click)="readyForCollection(order)"
          class="btn btn-sm btn-primary"
        >
          Ready for Collection
        </button>
        <button
          *ngIf="order.status == 'PENDING'"
          (click)="checkStatus(order)"
          class="btn btn-sm btn-primary"
        >
          Check Status
        </button>
        <span *ngIf="order.status; in: ['PAID', 'PENDING']">&nbsp;</span>
        <a
          class="btn btn-sm btn-primary"
          target="_blank"
          [routerLink]="['/shop/order', order.reference]"
        >
          <i class="fa fa-search"></i>
        </a>
      </td>
    </tr>
    <tr *ngIf="!orders || orders.length == 0">
      <td colspan="7">There are no orders to display</td>
    </tr>
  </tbody>
</table>

<div class="btn-group" role="group">
  <button
    class="btn btn-primary btn-sm"
    [disabled]="!canPrev()"
    (click)="prev()"
    href="#"
  >
    &lt;
  </button>
  <button class="btn btn-sm" disabled="true">{{ page() }}</button>
  <button
    class="btn btn-primary btn-sm"
    [disabled]="!canNext()"
    (click)="next()"
    href="#"
  >
    &gt;
  </button>
</div>
