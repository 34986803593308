import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { APIService, GetProductQuery } from '../../API.service';
import { ProductVariantPresetsService } from '../product-variant-presets.service';

@Component({
  selector: 'app-admin-create-product-variant',
  templateUrl: './admin-create-product-variant.component.html',
  styleUrls: ['./admin-create-product-variant.component.scss']
})
export class AdminCreateProductVariantComponent implements OnInit {

  form: FormGroup;
  productID: string | null = null;
  product: GetProductQuery | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: APIService,
    public productVariantPresets: ProductVariantPresetsService
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(null),
      code: new FormControl(null),
      price: new FormControl(null),
      productID: new FormControl(null),
      description: new FormControl(null),
      inStock: new FormControl(null),
      active: new FormControl(null)
    });

    this.route.params.subscribe(params => {
      this.productID = params['productID'];

      this.form.patchValue({
        'productID': this.productID
      });

      this.api.GetProduct(this.productID).then(product => {
        this.product = product;
      });
    });
  }

  selectTemplate(template) {
    this.form.patchValue(template);
  }

  create() {
    if (!this.form.value.inStock) {
      this.form.patchValue({ inStock: false });
    }

    if (!this.form.value.active) {
      this.form.patchValue({ active: false });
    }

    console.log(this.form.value);
    this.api.CreateProductVariant(this.form.value).then(() => {
      this.router.navigate(['admin/products/variants', this.productID]);
    });
  }
}
