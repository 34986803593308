import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { APIService, GetProductQuery } from '../../API.service';

@Component({
  selector: 'app-admin-update-product-variant',
  templateUrl: './admin-update-product-variant.component.html',
  styleUrls: ['./admin-update-product-variant.component.scss']
})
export class AdminUpdateProductVariantComponent implements OnInit {

  form: FormGroup;
  loaded: boolean = false;
  productID: string | null = null;
  product: GetProductQuery | null = null;

  constructor(private route: ActivatedRoute, private router: Router, private api: APIService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null),
      code: new FormControl(null),
      price: new FormControl(null),
      productID: new FormControl(null),
      description: new FormControl(null),
      inStock: new FormControl(null),
      active: new FormControl(null)
    });

    this.route.params.subscribe(params => {
      this.api.GetProductVariant(params['productVariantID']).then(productVariant => {
        this.form.patchValue({
          id: productVariant.id,
          name: productVariant.name,
          code: productVariant.code,
          price: productVariant.price,
          productID: productVariant.productID,
          description: productVariant.description,
          inStock: productVariant.inStock,
          active: productVariant.active
        });

        this.loaded = true;
      });

      this.productID = params['productID'];

      this.api.GetProduct(this.productID).then(product => {
        this.product = product;
      });
    });
  }

  save() {
    this.api.UpdateProductVariant(this.form.value).then(() => {
      this.router.navigate(['admin/products/variants', this.productID]);
    });
  }

}
