import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { APIService } from '../../API.service';
import { Product, ProductVariant } from '../../models/index';
import { ShoppingCartService, ShoppingCart } from '../shopping-cart.service';
import {
  CheckoutService,
  ProvisionalOrder,
  DeliveryAddress,
  DeliveryMethod,
} from '../checkout.service';
import { TakePaymentComponent } from '../take-payment/take-payment.component';

interface MappedShoppingCartItem {
  productVariant: ProductVariant;
  product: Product;
  quantity: number;
}

interface MappedShoppingCart {
  items: Array<MappedShoppingCartItem>;
  totalCost: number;
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  error: string | null = null;
  form: FormGroup;
  order: ProvisionalOrder;
  cart: ShoppingCart | null = null;
  mappedCart: MappedShoppingCart | null = null;
  products: Array<Product> | null = null;
  productVariants: Array<ProductVariant> | null = null;
  orderBeingPlaced: boolean = false;

  constructor(
    private router: Router,
    private api: APIService,
    private shoppingCart: ShoppingCartService,
    private checkoutService: CheckoutService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      emailAddress: new FormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      contactNumber: new FormControl(null, [
        Validators.required,
        Validators.minLength(11),
      ]),
      deliveryMethod: new FormControl(null, [Validators.required]),
      notes: new FormControl(null),
      addressLine1: new FormControl(null),
      addressLine2: new FormControl(null),
      town: new FormControl(null),
      postalCode: new FormControl(null),
      marketingAllowed: new FormControl(null),
    });

    this.form.patchValue({
      deliveryMethod: 'collection',
    });

    this.api.ListProducts().then((result) => {
      this.products = result.items;

      this.api.ListProductVariants(null, 1000).then((result) => {
        this.productVariants = result.items;
        this.update();
      });
    });
  }

  placeOrder() {
    if (!this.form.value.marketingAllowed) {
      this.form.patchValue({ marketingAllowed: false });
    }

    const formValue = this.form.value;

    const provisionalOrder: ProvisionalOrder = {
      name: formValue.name,
      emailAddress: formValue.emailAddress,
      contactNumber: formValue.contactNumber,
      notes: formValue.notes,
      deliveryMethod: formValue.deliveryMethod,
      marketingAllowed: formValue.marketingAllowed,
      cart: this.cart,
    };

    if (provisionalOrder.deliveryMethod != DeliveryMethod.COLLECTION) {
      provisionalOrder.deliveryAddress = {
        addressLine1: formValue.addressLine1,
        addressLine2: formValue.addressLine2,
        town: formValue.town,
        postalCode: formValue.postalCode,
        countryCode: 'GB',
      };
    }

    this.error = null;
    this.orderBeingPlaced = true;

    this.checkoutService
      .createOrder(provisionalOrder)
      .then((order) => {
        const modalRef = this.modalService.open(TakePaymentComponent, {
          backdrop: 'static',
          keyboard: false,
        });

        modalRef.componentInstance.order = order;

        modalRef.result
          .then(() => {
            this.checkoutService
              .completeOrder(order.reference)
              .then(() => {
                this.shoppingCart.reset();
                this.router.navigate(['/shop/order/', order.reference]);
              })
              .catch((error) => {
                this.error = error;
                this.orderBeingPlaced = false;
              });
          })
          .catch((error) => {
            this.checkoutService.cancelOrder(order.reference);

            this.orderBeingPlaced = false;
          });
      })
      .catch((error) => {
        this.error = error;
        this.orderBeingPlaced = false;
      });
  }

  update() {
    this.cart = this.shoppingCart.get();
    this.mapCart();
  }

  mapCart() {
    this.mappedCart = {
      items: [],
      totalCost: 0,
    };

    if (this.cart) {
      for (const productVariantID in this.cart) {
        const productVariant = this.productVariants.find(
          (productVariant) => productVariant.id == productVariantID
        );

        if (null == productVariant) {
          this.reset();
          return;
        }

        const product = this.products.find(
          (product) => product.id == (productVariant as any).productID
        );

        if (null == product) {
          this.reset();
          return;
        }

        this.mappedCart.items.push({
          productVariant,
          product,
          quantity: this.cart[productVariantID],
        });

        this.mappedCart.totalCost +=
          this.cart[productVariantID] * productVariant.price;
      }
    }
  }

  clearMappedCart() {
    this.mappedCart = null;
  }

  remove(item) {
    this.shoppingCart.deleteFromCart(item.productVariant.id);
    this.update();
  }

  reset() {
    this.clearMappedCart();
    this.shoppingCart.reset();
    this.update();
  }
}
