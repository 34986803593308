<div *ngIf="error" class="alert alert-danger">
  {{ error }}
</div>

<div *ngif="!error && !order" class="alert alert-info">
  Loading...
</div>

<div *ngIf="order">
  <div *ngIf="order.status == 'PAID'">
    <div class="alert alert-success">
      Your order is confirmed and your reference is <b>{{ order.reference }}</b>.
      <br /><br />
      You will receive an email when your order is out for delivery or is ready for collection.
    </div>

    <br />
  </div>
  <div *ngIf="order.status == 'READY_FOR_COLLECTION'">
    <div class="alert alert-success">
      <p>Your order is <b>ready for collection</b> and your reference is <b>{{ order.reference }}</b></p>

      <p>Our shop is open on Monday, Wednesday and Friday between 11am and 5pm</p>
  
      <p>
          Bond Brews Ltd<br />
          Unit 3 & 4<br />
          South Barns<br />
          Gardeners Green Farm<br />
          Heathlands Road<br />
          Wokingham<br />
          RG40 3AS 
      </p>
    </div>

    <br />
  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <h4>Order Details</h4>

      <br />

      <table class="table table-striped">
        <tr>
          <th>
            Reference
          </th>
          <td>
            {{ order.reference }}
          </td>
        </tr>
        <tr>
          <th>
            Created At
          </th>
          <td>
            {{ order.createdAt | date: 'medium' }}
          </td>
        </tr>
        <tr>
          <th>
            Name
          </th>
          <td>
            {{ order.name }}
          </td>
        </tr>
        <tr>
          <th>
            Status
          </th>
          <td [ngSwitch]="order.status">
            <span *ngSwitchCase="'PENDING'">
              Your order is awaiting payment details.
            </span>
            <span *ngSwitchCase="'CANCELLED'">
              Your order has been cancelled. This usually occurs when you exit the payment process or the transaction fails.
            </span>
            <span *ngSwitchCase="'PAID'">
              Your payment has been processed and your order is confirmed.
            </span>
            <span *ngSwitchCase="'READY_FOR_COLLECTION'">
              Your order is ready for collection
            </span>
            <span *ngSwitchDefault>
              Unknown order status.
            </span>
          </td>
        </tr>
        <tr>
          <th>Order Notes</th>
          <td *ngIf="order.notes">
            {{ order.notes }}
          </td>
          <td *ngIf="!order.notes">
            No order notes were provided
          </td>
        </tr>
      </table>
    </div>
    <div class="col-12 col-md-6">
      <h4>Order Items</h4>

      <br />

      <table class="table table-striped">
        <thead>
          <tr>
            <th>Code</th>
            <th>Product</th>
            <th>Option</th>
            <th>Quantity</th>
            <th>Total Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of order.orderItems">
            <td>
              {{ item.productCode }}-{{ item.productVariantCode }}
            </td>
            <td>
              {{ item.productName }}
            </td>
            <td>
              {{ item.productVariantName }}
            </td>
            <td>
              {{ item.quantity }}
            </td>
            <td>
              £{{ item.quantity * item.productVariantPrice | number: "1.2-2" }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4"></td>
            <td>
              <b>£{{ order.total | number: "1.2-2" }}</b>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

