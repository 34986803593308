import { Component, OnInit } from '@angular/core';

import { APIService } from '../../API.service';
import { Product } from '../../models/index';

@Component({
  selector: 'app-shop-front',
  templateUrl: './shop-front.component.html',
  styleUrls: ['./shop-front.component.scss']
})
export class ShopFrontComponent implements OnInit {

  products: Array<Product> | null;

  constructor(private api: APIService) { }

  ngOnInit() {
    this.api.ListProducts().then(result => {
      this.products = result.items.filter(product => product.active);

      this.products.sort((a, b) => {
        return b.sortPriority - a.sortPriority;
      });
    });
  }

}
