<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="/admin/products">Products</a>
    </li>
    <li class="breadcrumb-item active">{{ form.get('name').value }}</li>
  </ol>
</nav>

<form [formGroup]="form">
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="slug">Slug</label>
      <input formControlName="slug" type="text" class="form-control" required />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="name">Name</label>
      <input formControlName="name" type="text" class="form-control" required />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="categoryID">Category</label>
      <select formControlName="categoryID" class="form-control">
        <option *ngFor="let category of categories" [ngValue]="category.id">
          {{ category.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="code">Code</label>
      <input formControlName="code" type="text" class="form-control" required />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="description">Information</label>
      <textarea formControlName="description" class="form-control"></textarea>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="allergens">Allergens</label>
      <input formControlName="allergens" type="text" class="form-control" required />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <label for="sortPriority">Display Priority</label>
      <select formControlName="sortPriority" class="form-control">
        <option value="20">Very High</option>
        <option value="15">High</option>
        <option value="10">Medium</option>
        <option value="5">Low</option>
        <option value="0">Very Low</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="inStock" />
        <label for="inStock" class="form-check-label">In Stock</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="active" />
        <label for="active" class="form-check-label">Active</label>
      </div>
    </div>
  </div>
  <button class="btn btn-primary btn-sm" (click)="create()">Create</button>
</form>
