import { Injectable } from '@angular/core';

export interface ShoppingCart {
  [productVariantID: string]: number;
}

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  cart: ShoppingCart | null = null;
  callbacks: Array<(cart: ShoppingCart) => void> = [];

  constructor() {
    const valueFromStorage = localStorage.getItem('cart');

    if (null !== valueFromStorage) {
      this.cart = JSON.parse(valueFromStorage) as ShoppingCart;
    } else {
      this.cart = { };
    }
  }

  onUpdate(callback) {
    this.callbacks.push(callback);

    callback(this.cart);
  }

  get(): ShoppingCart {
    return this.cart;
  }

  save() {
    const valueForStorage = JSON.stringify(this.cart);
    localStorage.setItem('cart', valueForStorage);

    this.callbacks.forEach(callback => callback(this.cart));
  }

  addToCart(productVariantID: string, quantity: number) {
    if (productVariantID in this.cart) {
      this.cart[productVariantID] += quantity;
    } else {
      this.cart[productVariantID] = quantity;
    }

    this.save();
  }

  deleteFromCart(productVariantID: string) {
    delete this.cart[productVariantID];

    this.save();
  }

  updateQuantityInCart(productVariantID: string, quantity: number) {
    if (quantity < 1) {
      this.deleteFromCart(productVariantID);
    } else {
      this.cart[productVariantID] = quantity;

      this.save();
    }
  }

  reset() {
    this.cart = { };
    this.save();
  }

}
