<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active">Products</li>
  </ol>
</nav>

<a class="btn btn-primary btn-sm" routerLink="create">New Product</a>

<br /><br />

<table class="table">
  <thead>
    <tr>
      <th>Code</th>
      <th>Name</th>
      <th>Display Priority</th>
      <th>Active</th>
      <th>In Stock</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let product of products">
      <td>{{ product.code }}</td>
      <td>{{ product.name }}</td>
      <td>{{ sortPriorityToString(product.sortPriority) }}</td>
      <td>{{ product.active ? 'Active' : 'In Active' }}</td>
      <td>{{ product.inStock ? 'In Stock' : 'Out of Stock' }}</td>
      <td style="width: 1px; white-space: nowrap">
        <button (click)="openUpdateImageModal(product)" class="btn btn-sm btn-primary">
          <i class="fa fa-image"></i>
        </button>
        &nbsp;
        <a class="btn btn-sm btn-primary" [routerLink]="['variants', product.id]">
          <i class="fa fa-beer"></i>
        </a>
        &nbsp;
        <a class="btn btn-sm btn-primary" [routerLink]="['update', product.id]">
          <i class="fa fa-edit"></i>
        </a>
        &nbsp;
        <button (click)="delete(product.id)" type="button" class="btn btn-sm btn-danger">
          <i class="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
