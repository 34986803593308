import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Amplify Imports
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';

Amplify.configure (awsconfig);

// Bootstrap Imports
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Local Imports
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { ProductComponent } from './product/product.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { TakePaymentComponent } from './take-payment/take-payment.component';
import { ShopFrontComponent } from './shop-front/shop-front.component';
import { ContactComponent } from './contact/contact.component';
import { OrderComponent } from './order/order.component';
import { AdminComponent } from './admin/admin.component';
import { AdminProductsComponent } from './admin-products/admin-products.component';
import { AdminProductTableComponent } from './admin-product-table/admin-product-table.component';
import { AdminCreateProductComponent } from './admin-create-product/admin-create-product.component';
import { AdminUpdateProductComponent } from './admin-update-product/admin-update-product.component';
import { AdminProductVariantTableComponent } from './admin-product-variant-table/admin-product-variant-table.component';
import { AdminCreateProductVariantComponent } from './admin-create-product-variant/admin-create-product-variant.component';
import { AdminUpdateProductVariantComponent } from './admin-update-product-variant/admin-update-product-variant.component';
import { AdminUpdateProductImageComponent } from './admin-update-product-image/admin-update-product-image.component';
import { AdminCategoriesComponent } from './admin-categories/admin-categories.component';
import { AdminCategoryTableComponent } from './admin-category-table/admin-category-table.component';
import { AdminCreateCategoryComponent } from './admin-create-category/admin-create-category.component';
import { AdminUpdateCategoryComponent } from './admin-update-category/admin-update-category.component';
import { AdminOrdersComponent } from './admin-orders/admin-orders.component';
@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    ProductComponent,
    CheckoutComponent,
    TakePaymentComponent,
    ShopFrontComponent,
    ContactComponent,
    OrderComponent,
    AdminComponent,
    AdminProductsComponent,
    AdminProductTableComponent,
    AdminCreateProductComponent,
    AdminUpdateProductComponent,
    AdminProductVariantTableComponent,
    AdminCreateProductVariantComponent,
    AdminUpdateProductVariantComponent,
    AdminUpdateProductImageComponent,
    AdminCategoriesComponent,
    AdminCategoryTableComponent,
    AdminCreateCategoryComponent,
    AdminUpdateCategoryComponent,
    AdminOrdersComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AmplifyUIAngularModule,
    NgbModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
