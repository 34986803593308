<div *ngIf="mappedCart; else loading">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/shop">Shop</a>
      </li>
      <li class="breadcrumb-item active">Checkout</li>
    </ol>
  </nav>

  <br />

  <div *ngIf="error">
    <div class="alert alert-danger">
      {{ error }}
    </div>

    <br />
  </div>

  <div class="row">
    <div class="col">
      <h4>Your Order</h4>

      <br />

      <table class="table table-striped">
        <thead>
          <tr>
            <th>Product</th>
            <th>Option</th>
            <th>Quantity</th>
            <th>Total Cost</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of mappedCart.items">
            <td>
              <a [routerLink]="['/shop/product', item.product.id]">
                {{ item.product.name }}
              </a>
            </td>
            <td>{{ item.productVariant.name }}</td>
            <td>{{ item.quantity }}</td>
            <td>
              £{{ item.quantity * item.productVariant.price | number: "1.2-2" }}
            </td>
            <td style="width: 1px; white-space: nowrap">
              <button class="btn btn-sm btn-danger" (click)="remove(item)">
                Remove
              </button>
            </td>
          </tr>
          <tr *ngIf="mappedCart.items.length == 0">
            <td colspan="5">
              There are no items in your shopping cart
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="3"></td>
            <td>
              <b>£{{ mappedCart.totalCost | number: "1.2-2" }}</b>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="col">
      <div class="card">
        <div class="card-body">
          <h5 card="card-title">Customer Information</h5>

          <br />

          <form [formGroup]="form">
            <div class="row">
              <div class="col mb-3">
                <label for="name">Name</label>
                <input formControlName="name" type="text" class="form-control" required />
              </div>
            </div>
            <div class="row">
              <div class="col mb-3">
                <label for="emailAddress">Email Address</label>
                <input formControlName="emailAddress" type="text" class="form-control" required />
              </div>
            </div>
            <div class="row">
              <div class="col mb-3">
                <label for="contactNumber">Contact Number</label>
                <input formControlName="contactNumber" type="text" class="form-control" required />

                <br />

                <div class="alert alert-info">
                  We require a telephone number so that we can contact you easily should we need to discuss your order.
                  We will never use your contact number for marketing purposes.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col mb-3">
                <label for="deliveryMethod">Dispatch Method</label>
                <select #deliveryMethod formControlName="deliveryMethod" class="form-control">
                  <option value="collection">Collection</option>
                  <!-- <option disabled value="local-delivery">Local Delivery</option> -->
                </select>

                <br />

                <div class="alert alert-warning">
                  Unfortunately, we are currently unable to offer a delivery service. Collection is available from our
                  brewery shop:
                  <br /><br />
                  Unit 3 & 4<br />
                  South Barns<br />
                  Gardeners Green Farm<br />
                  Heathlands Road<br />
                  Wokingham<br />
                  RG40 3AS
                  <br /><br />
                  Our shop is open on Monday, Wednesday and Friday between 11am and 5pm. You will receive an email when
                  your order is ready to collect.
                </div>
              </div>
            </div>
            <div *ngIf="this.deliveryMethod.value != 'collection'">
              <div class="row">
                <div class="col mb-3">
                  <label for="addressLine1">Address Line 1</label>
                  <input formControlName="addressLine1" type="text" class="form-control" required />
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                  <label for="addressLine2">Address Line 2</label>
                  <input formControlName="addressLine2" type="text" class="form-control" required />
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                  <label for="town">Town</label>
                  <select formControlName="town" class="form-control">
                    <option value="Wokingham">Wokingham</option>
                    <option value="Bracknell">Bracknell</option>
                    <option value="Binfield">Binfield</option>
                    <option value="Crowthorne">Crowthorne</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col mb-3">
                  <label for="postalCode">Postal Code</label>
                  <input formControlName="postalCode" type="text" class="form-control" required />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col mb-3">
                <label for="notes">Order Notes</label>
                <textarea class="form-control" style="height: 100px;" formControlName="notes" placeholder="Please use this space to note down any extra information about your order (e.g. gift pack selections)"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col mb-3">
                <div class="form-check">
                  <input type="checkbox" formControlName="marketingAllowed" class="form-check-input" />
                  <label for="marketingAllowed" class="form-check-label">
                    Please check this box if you are happy for us to contact you in future with
                    offers, updates, news and promotions.
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col mb-3">
                <button (click)="placeOrder()" [disabled]="orderBeingPlaced || mappedCart.totalCost < 0.01 || form.invalid" class="btn btn-primary">
                  Place Order
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading> Loading... </ng-template>
