import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { APIService, GetProductQuery } from '../../API.service';
import { ProductVariant } from '../../models/index';
import { ShoppingCartService } from '../shopping-cart.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  added: boolean = false;
  product: GetProductQuery | null = null;
  productVariants: Array<ProductVariant> | null = null;
  selectedProductVariant: ProductVariant | null = null;
  quantity: number = 1;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private api: APIService,
    private shoppingCart: ShoppingCartService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.api.GetProduct(params['productID']).then((product) => {
        this.product = product;
      });

      this.api
        .ListProductVariants({ productID: { eq: params['productID'] } }, 1000)
        .then((result) => {
          this.productVariants = result.items.filter(
            (productVariant) => productVariant.active
          );

          this.productVariants.sort((a, b) => a.price - b.price);

          for (var i = 0; i < this.productVariants.length; i++) {
            const productVariant = this.productVariants[i];

            if (productVariant.inStock) {
              this.selectedProductVariant = productVariant;
              break;
            }
          }
          if (this.productVariants.length > 1) {
            this.selectedProductVariant =
              this.productVariants.find(
                (productVariant) => productVariant.inStock
              ) || this.productVariants[0];
          }
        });
    });
  }

  filterQuantityToInt() {
    this.quantity = Math.round(this.quantity);
  }

  addToCart() {
    this.shoppingCart.addToCart(this.selectedProductVariant.id, this.quantity);
    this.added = true;

    setTimeout(() => {
      this.added = false;
    }, 1000);
  }

  addToCartCheckout() {
    this.shoppingCart.addToCart(this.selectedProductVariant.id, this.quantity);
    this.router.navigate(['/shop/checkout']);
  }
}
