import { Component, OnInit } from '@angular/core';

import { APIService } from '../../API.service';
import { Category } from '../../models/index';

@Component({
  selector: 'app-admin-category-table',
  templateUrl: './admin-category-table.component.html',
  styleUrls: ['./admin-category-table.component.scss']
})
export class AdminCategoryTableComponent implements OnInit {

  categories: Array<Category> | null;

  constructor(private api: APIService) { }

  ngOnInit() {
    this.updateCategories();
  }

  updateCategories() {
    this.categories = null;

    this.api.ListCategorys().then(result => {
      this.categories = result.items;
    });
  }

  delete(categoryID) {
    this.api.DeleteCategory({ id: categoryID }).then(() => {
      this.updateCategories();
    });
  }

}
