<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active">Categories</li>
  </ol>
</nav>

<a class="btn btn-primary btn-sm" routerLink="create">New Category</a>

<br /><br />

<table class="table">
  <thead>
    <tr>
      <th>Slug</th>
      <th>Name</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let category of categories">
      <td>{{ category.slug }}</td>
      <td>{{ category.name }}</td>
      <td style="width: 1px; white-space: nowrap">
        <a class="btn btn-sm btn-primary" [routerLink]="['update', category.id]">
          <i class="fa fa-edit"></i>
        </a>
        &nbsp;
        <button (click)="delete(category.id)" type="button" class="btn btn-sm btn-danger">
          <i class="fa fa-trash"></i>     
        </button>
      </td>
    </tr>
  </tbody>
</table>
