import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';

import { CheckoutService } from '../checkout.service';
import { Order } from '../../models/index';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  order: Order | null = null;
  error: string | null = null;

  constructor(private route: ActivatedRoute, private checkoutService: CheckoutService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.checkoutService.getOrder(params['orderReference'])
        .then((order) => this.order = order)
        .catch(error => {
          this.error = "Error retrieving order"
        });
      });
  }

}
