import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { APIService, GetProductQuery } from '../../API.service';
import { Product, ProductVariant } from '../../models/index';

@Component({
  selector: 'app-admin-product-variant-table',
  templateUrl: './admin-product-variant-table.component.html',
  styleUrls: ['./admin-product-variant-table.component.scss'],
})
export class AdminProductVariantTableComponent implements OnInit {
  productVariants: Array<ProductVariant> | null;
  productID: string | null;
  product: GetProductQuery | null;

  constructor(private route: ActivatedRoute, private api: APIService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.productID = params['productID'];

      this.updateProductVariants();

      this.api.GetProduct(this.productID).then((product) => {
        this.product = product;
      });
    });
  }

  updateProductVariants() {
    this.productVariants = null;

    if (null === this.productID) {
      return;
    }

    this.api
      .ListProductVariants({ productID: { eq: this.productID } }, 1000)
      .then((result) => {
        this.productVariants = result.items;

        this.productVariants.sort((a, b) => a.price - b.price);
      });
  }

  delete(productVariantID) {
    this.api.DeleteProductVariant({ id: productVariantID }).then(() => {
      this.updateProductVariants();
    });
  }
}
