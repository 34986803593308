import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { APIService } from '../../API.service';
import { Product } from '../../models/index';
import { AdminUpdateProductImageComponent } from '../admin-update-product-image/admin-update-product-image.component';

@Component({
  selector: 'app-admin-product-table',
  templateUrl: './admin-product-table.component.html',
  styleUrls: ['./admin-product-table.component.scss']
})
export class AdminProductTableComponent implements OnInit {

  products: Array<Product> | null;

  constructor(private api: APIService, private modalService: NgbModal) { }

  ngOnInit() {
    this.updateProducts();
  }

  updateProducts() {
    this.products = null;

    this.api.ListProducts().then(result => {
      this.products = result.items;

      this.products.sort((a, b) => {
        if (a.sortPriority == b.sortPriority) {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          }

          return 0;
        }

        return b.sortPriority - a.sortPriority;
      });
    });
  }

  delete(productID) {
    this.api.DeleteProduct({ id: productID }).then(() => {
      this.updateProducts();
    });
  }

  openUpdateImageModal(product) {
    const modalRef = this.modalService.open(AdminUpdateProductImageComponent);
    modalRef.componentInstance.product = product;
  }

  sortPriorityToString(priority) {
    switch (priority) {
      case 20: return 'Very High';
      case 15: return 'High';
      case 10: return 'Medium';
      case 5: return 'Low';
      case 0: return 'Very Low';
    }

    return 'Unknown';
  }

}
