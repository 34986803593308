<div id="navigation" class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
  <!-- <h5 class="my-0 mr-md-auto font-weight-normal">Bond Brews</h5> -->
  <a class="my-0 mr-md-auto" routerLink="/">
    <img style="height: 2.5rem;" src="/assets/bond-logo.png" alt="Bond Brews" />
  </a>

  <nav class="nav my-2 my-md-0 mr-md-3">
    <a class="nav-link p-2 text-dark" routerLink="/shop">Shop</a>
    <a class="nav-link p-2 text-dark" routerLink="/contact">Contact</a>
    <a *ngIf="itemCount > 0" class="nav-link p-2 btn btn-sm btn-success" routerLink="/shop/checkout">
      <i class="fa fa-shopping-cart"></i>&nbsp;
      Checkout
      &nbsp;
      <span class="badge badge-light">{{ itemCount }}</span>
    </a>
  </nav>  
</div>

<div class="container">
  <router-outlet></router-outlet>
</div>
