import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { APIService } from '../../API.service';

@Component({
  selector: 'app-admin-update-category',
  templateUrl: './admin-update-category.component.html',
  styleUrls: ['./admin-update-category.component.scss']
})
export class AdminUpdateCategoryComponent implements OnInit {

  form: FormGroup;
  loaded: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private api: APIService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(null),
      slug: new FormControl(null),
      name: new FormControl(null)
    });

    this.route.params.subscribe(params => {
      this.api.GetCategory(params['categoryID']).then(category => {
        this.form.patchValue({
          id: category.id,
          name: category.name,
          slug: category.slug
        });

        this.loaded = true;
      });
    });
  }

  save() {
    this.api.UpdateCategory(this.form.value).then(() => {
      this.router.navigate(['admin/categories']);
    });
  }

}
