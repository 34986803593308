import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { APIService } from '../../API.service';

@Component({
  selector: 'app-admin-create-category',
  templateUrl: './admin-create-category.component.html',
  styleUrls: ['./admin-create-category.component.scss']
})
export class AdminCreateCategoryComponent implements OnInit {

  form: FormGroup;

  constructor(private router: Router, private api: APIService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      slug: new FormControl(null),
      name: new FormControl(null)
    });
  }

  create() {
    this.api.CreateCategory(this.form.value).then(() => {
      this.router.navigate(['admin/categories']);
    });
  }

}
