<div class="modal-header">
  <h4 class="modal-title">Payment Form</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div [hidden]="pending" id="sumup-card"></div>
  <div [hidden]="!pending">Please wait, verifying payment with provider...</div>
</div>
