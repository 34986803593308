import { Injectable } from '@angular/core';

import { API } from 'aws-amplify';
import { ShoppingCart } from './shopping-cart.service';
import { Order } from '../models/index';
import { promise } from 'protractor';

export enum DeliveryMethod {
  COLLECTION = 'collection',
  LOCAL_DELIVERY = 'local-delivery',
}

export interface DeliveryAddress {
  addressLine1: string;
  addressLine2?: string;
  town: string;
  postalCode: string;
  countryCode: string;
}

export interface ProvisionalOrder {
  name: string;
  emailAddress: string;
  contactNumber: string;
  notes: string;
  deliveryMethod: DeliveryMethod;
  deliveryAddress?: DeliveryAddress;
  marketingAllowed: boolean;
  cart: ShoppingCart;
}

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  constructor() {}

  async createOrder(order: ProvisionalOrder): Promise<Order> {
    const result = await API.post('jarscideronlineshopcheckout', '/orders', {
      body: order,
    });

    return new Promise((resolve, reject) => {
      if (result.success) {
        resolve(result.order);
      } else {
        reject('There was an error placing this order');
      }
    });
  }

  async checkPaymentStatus(orderReference: string): Promise<string> {
    const result = await API.get(
      'jarscideronlineshopcheckout',
      '/paymentStatus/' + orderReference,
      {}
    );

    return new Promise((resolve, reject) => {
      if (result.success) {
        resolve(result.status);
      } else {
        reject('There was an error checking the payment status');
      }
    });
  }

  async getOrder(orderReference: string): Promise<Order> {
    const result = await API.get(
      'jarscideronlineshopcheckout',
      '/orders/' + orderReference,
      {}
    );

    return new Promise((resolve, reject) => {
      if (result.success) {
        resolve(result.order);
      } else {
        reject('There was an error retrieving this order');
      }
    });
  }

  async completeOrder(orderReference: string): Promise<string> {
    const result = await API.put(
      'jarscideronlineshopcheckout',
      '/orders/' + orderReference,
      {
        body: {
          status: 'PAID',
        },
      }
    );

    return new Promise((resolve, reject) => {
      if (result.error) {
        reject(result.error);
      } else {
        resolve(result.status);
      }
    });
  }

  async markReadyForCollection(orderReference: string): Promise<string> {
    const result = await API.put(
      'jarscideronlineshopcheckout',
      '/orders/' + orderReference,
      {
        body: {
          status: 'READY_FOR_COLLECTION',
        },
      }
    );

    return new Promise((resolve, reject) => {
      if (result.error) {
        reject(result.error);
      } else {
        resolve(result.status);
      }
    });
  }

  async cancelOrder(orderReference: string): Promise<void> {
    const result = await API.put(
      'jarscideronlineshopcheckout',
      '/orders/' + orderReference,
      {
        body: {
          status: 'CANCELLED',
        },
      }
    );

    return new Promise((resolve, reject) => {
      if (result.error) {
        reject(result.error);
      } else {
        resolve();
      }
    });
  }
}
